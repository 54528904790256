$side-width: 640px;
$modal-header-height: 40px;

.modal.articleModal {

    font-size: 0.875rem !important;

    .navigate_article-sticky {
        position: sticky;
        top: 50%;
        user-select: none;

        .navigate_article {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;

            svg{
                transition: all .2s ease-in-out;
                fill: $themeTitleBlack;
            }

            &:hover { 
                svg {
                    transform: scale(1.5);
                }
            }
    
            &.navigate_article--next {
                // right: 1rem;
                right: calc((70vw - 640px - 8rem)/4);
                
            }
        
            &.navigate_article--prev {
                // left: 1rem;
                left: calc((70vw - 640px - 8rem)/4);
            }
        }
    }


    &.right {

        &.fade {
            .modal-dialog {
                transform: translate(100%, 0);
            }
        }

        &.show {
            .modal-dialog {
                transform: none;
            }
        }

        .modal-dialog {
            position: fixed;
            right: 0;
            // top: $navbarHeight;
            margin: 0;
            padding: 0;
            width: 100%;
            // max-width: $side-width;
            max-width: 100%;
            background: #fff;

            @include desktop()
            {
                width: 70vw;
            }

            .modal-content {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                border-width: 0px;

                .modal-header {
                    height: $modal-header-height;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;

                    .action__bar {
                        width: 100%;
                        max-width: 100%;
                        margin: 0 auto;

                        @include desktop()
                        {
                            width: $side-width;
                        }
                    }
                }

                .modal-body {
                    // height: calc(100vh - #{$modal-header-height} - #{$navbarHeight});
                    height: calc(100vh - #{$modal-header-height});
                    height: calc(100dvh - #{$modal-header-height});
                    overflow-y: auto;

                    .article_item,.article_item_app-tab {
                        width: 100%;
                        max-width: 100%;
                        margin: 0 auto;

                        @include desktop()
                        {
                            width: $side-width;
                        }
                    }
                }
            }
        }
    }



// 	&.left .modal-dialog,
// 	&.right .modal-dialog {
// 		position: fixed;
// 		margin: auto;
//         width: $side-width;
//         max-width: 100%;
// 		height: 100%;
// 		-webkit-transform: translate3d(0%, 0, 0);
// 		    -ms-transform: translate3d(0%, 0, 0);
// 		     -o-transform: translate3d(0%, 0, 0);
// 		        transform: translate3d(0%, 0, 0);
// 	}

// 	&.left .modal-content,
// 	&.right .modal-content {
// 		height: 100%;
// 		overflow-y: auto;
// 	}
	
// 	// &.left .modal-body,
// 	// &.right .modal-body {
// 	// 	// padding: 15px 15px 80px;
// 	// 	// padding: 15px;
// 	// }

// /*Left*/
// 	&.left .modal-dialog {
// 		left: -$side-width;
// 		-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
// 		   -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
// 		     -o-transition: opacity 0.3s linear, left 0.3s ease-out;
// 		        transition: opacity 0.3s linear, left 0.3s ease-out;
// 	}
	
// 	&.left.in .modal-dialog {
// 		left: 0;
// 	}
        
// /*Right*/
// 	&.right .modal-dialog {
// 		right: -$side-width;
// 		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
// 		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
// 		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
// 		        transition: opacity 0.3s linear, right 0.3s ease-out;
// 	}
	
// 	&.right.in .modal-dialog {
// 		right: 0;
// 	}

	// /* ----- MODAL STYLE ----- */
	// .modal-content {
	// 	border-radius: 0;
	// 	border: none;
	// }

	// .modal-header {
	// 	border-bottom-color: #EEEEEE;
	// 	background-color: #FAFAFA;
	// }

	// /* ----- scrollable fix ----- */
	// .modal-dialog.modal-dialog-scrollable {
	// 	max-height: none !important;

	// 	.modal-content {
	// 		max-height: none !important;
	// 	}
	// }
}

.modal.fullsize {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .modal-dialog {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); // Centers the modal
        width: 90vw; // Adjusts width dynamically
        max-width: 90vw;
        height: 90vh; // Adjusts height dynamically
        max-height: 90vh;
        margin: 0;
        background: #fff;
        border-radius: 8px; // Optional for rounded corners

        @include desktop() {
            width: 95vw;
            max-width: 95vw;
            height: 95vh;
            max-height: 95vh;
        }

        .modal-content {
            height: 100%;
            overflow: auto;
            border-radius: 8px; // Keeps modal looking clean

            .modal-body {
                height: calc(100% - #{$modal-header-height});
                overflow-y: auto;
                overflow-x: hidden;
            }
        }
    }
}