.tw_sub_modal {

    .modal-title {
        font-weight: bold;
        color: #333;
    }

    @include desktop() {

        .modal-content {
            width: 700px;
        }
    }

    .payments_header {
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;

        .switch-container {
            display: flex;
            justify-content: center;
            align-items: center;

            .custom-switch {
                margin: -7px 15px 0 10px;
                width: 50px;
            }

            .save {
                color: $primary;
                white-space: nowrap;
                font-size: 80%;
                background: lighten($primary,40%);
                padding: 4px 6px;
            }
        }
    }

    .mnth-gap {
        // padding-left: 4rem;
    }

    .plan_item_section {
        width: 100%;
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 1fr;

        @include desktop() {
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            // grid-template-columns: 1fr 1fr 1fr;
        }

        & > .plan_item {
            display: flex;
            background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
            border: 2px solid rgba(223, 224, 227, 1);
            border-radius: 4px;
            // padding: 1rem;
            padding: 10px;
            font-size: 14px;
            margin-right: 10px;
            cursor: pointer;
            @extend .shadow;
            gap:0.5rem;
            justify-content: space-between;
            align-items: center;
            // @include desktop() {
            //     flex-direction: column;
            //     text-align: center;
            // }

            &.active {
                border-color: $primary !important;
            }

            .plan_amount {
                font-weight: 600;
            }
            .plan_name {
                // font-weight: 600;
                font-weight: 700;
            }
        }

    }

.info-wrapper {

    & > .info-item {

        &:not(:last-child) {
            margin-bottom: 1rem;
        }
        
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 1rem;
            font-size: 2rem;

            svg {
                width: 28px;
                height: auto;
                color: $secondary;
            }
        }

        .info {
            
            .title {
                font-weight: 600;
                color: $secondary;
            }

            .info-toggle {
                i {
                    transform: rotate(90deg);
                }
                &.active {
                    i {
                        transform: rotate(270deg);
                    }
                }
            }

            .desc {
                color: $secondary;
            }
        }

        

        &.active {
            .icon {
                svg {
                    color: $primary;
                }
            }
            .info {
                .title {
                    color: $dark;
                }

                .desc {
                    color: $dark;
                }
            }
        }
    }
}

}