$rsBottomHeightMobile: 6rem;

.main.organize {
    background-color: $themeGrey;

    .dropdown-menu.show {
        max-height: 50vh;
        max-height: 50dvh;
        overflow-y: auto;
    }

    &.contained { 
        .oragnize__main {
            max-width: $c2MaxWidth;
            margin: 0 auto;
            border-left: 1px solid $border-color;
            border-right: 1px solid $border-color;
        }
        
        .oragnize_container {

            .oragnize_container-outer {
                max-width: $c2MaxWidth;
                margin: 0 auto;
                border-left: 1px solid $border-color;
                border-right: 1px solid $border-color;
            }
        }
    }

    .oragnize__main {
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: column;
        z-index: 2;
    //     justify-content: center;
    //     // margin: 0 -15px 15px -15px;
    //     padding: 0 15px;
        background-color: $white;
    
        .oragnize__top {
            display: flex;
            // flex-direction: column;
            align-items: center;
            padding: 20px;
            height: $rsTopHeight;
            // background-color: $white;
            @extend .border-bottom;

            .primary {
                font-size: 1.2rem;
                font-weight: 600;
                margin: -2px 0 4px 0;
            }

            .secondary {

            }
        }

        .oragnize__bottom {
            display: flex;
            align-items: center;
            height: $rsBottomHeightMobile;
            flex-direction: column;
            justify-content: flex-start;
            padding: 10px;
            @extend .border-bottom;
            height: auto;
            
            @include desktop {
                height: $rsBottomHeight;
                flex-direction: row;
                padding: 20px;
            }
            

            .title {
                font-weight: bold;
            }

            .top {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                @extend .res-flex-dir;
                
                .dropdown {
                    .btn {
                        font-size: 0.7rem;
                    }
                }

            }

            .bottom {
                margin-top: 10px;
                width: 100%;

            }
        }
    }

    .oragnize_container {
        background-color: $themeGrey3;
        height: calc(100vh - #{$navbarHeight} - #{$rsTopHeight} - #{$rsBottomHeightMobile});
        height: calc(100dvh - #{$navbarHeight} - #{$rsTopHeight} - #{$rsBottomHeightMobile});
        overflow-y: auto;
        overflow-y: overlay;
        margin-left: 5px;
        // scrollbar-gutter: stable both-edges;
        position: relative;

        @include desktop {
            height: calc(100vh - #{$navbarHeight} - #{$rsTopHeight} - #{$rsBottomHeight});
            height: calc(100dvh - #{$navbarHeight} - #{$rsTopHeight} - #{$rsBottomHeight});
        }

        .oragnize_container-outer {
            background-color: $white;
            min-height: 100%;
            // padding: 20px;

            

            .oragnize_source_wrapper--m {

            }

            .oragnize_source_wrapper {

                // .item__wrapper--checkbox {
                //     cursor: pointer;
                // }

                .oragnize_source_wrapper--header {
                    position: sticky;
                    top: 0;
                    background: $white;
                    z-index: 1;

                    th {
                        // border-bottom: 1px solid $border-color;
                        &:after,
                        &:before {
                          content: '';
                          position: absolute;
                          left: 0;
                          width: 100%;
                        }
                        &:before {
                          top: 0;
                        //   border-top: 3px solid blue;
                        }
                        &:after {
                          bottom: 0;
                          border-bottom: 1px solid $border-color;
                        }
                    }

                }
                .organize_sourece_wrapper--body {
                    td{
                        max-width: 15rem;
                    }
                }

                .oragnize_source__item {
                    border-top: 1px solid $border-color;

                    .fs-toggle-on, .fs-toggle-off
                    {
                        font-size: 150%; 
                    }

                    &:hover {
                        @include desktop {
                            background-color:lighten($text-muted, 50%);
                        }
                    }

                    &.oragnize_source__item--active {
                        background-color: lighten($primary, 40%);
                    }

                    .item__wrapper--source {
                        display: flex;
                        align-items: center;
                        margin: 5px 0;
                        max-width: 15rem;

                        @include mobile {
                            max-width: 40vw;
                        }

                        .item__secondary {
                            width: 100%;

                            .item__frequency {
                                @include mobile {
                                    font-size: 0.8rem;
                                }
                            }
                        }

                        .item__img {
                            width: 30px;
                            height: 30px;
                            margin-right: 5px;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-shrink: 0;

                            img {
                                width: 30px;
                                height: 30px;
                                // background-color: $themeGrey3;
                            }

                            &.item__img--folder {
                                background-color: $themeGrey3;
                            }
                        }

                        .item__title {
                            font-weight: 600;

                            @include mobile {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }

                    .item__time {
                        
                    }

                    .item__wrapper__actions {
                        display: flex;
                        width: 120px;
                        // justify-content: flex-end;
                        justify-content: center;
                        flex-shrink: 0;
                        // padding-right: 20px;

                        @include mobile {
                            width: auto;
                            padding-right: 20px;
                        }

                        .menu-mob {
                            .btn-link {
                                color: inherit;
                            }
                        }
                        
                        & > .item__wrapper__actions--item {
                            cursor: pointer;
                            width: 30px;
                            height: 30px;
                            margin-right: 5px;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: $themeGrey3;

                                
                            &:not(:first-child)
                            {
                                margin-left: 5px;
                            }

                            &.item__wrapper__actions--item_disable {
                                pointer-events: none;
                                opacity: 0.5;
                            }
                        }
                    }
                }

                .item__wrapper__actions_head {
                    display: flex;
                    // justify-content: flex-end;
                    justify-content: center;
                    flex-shrink: 0;
                    width: 100%;
                    // padding-right: 20px;
                    
                    & > .item__wrapper__actions--item {
                        &:not(:first-child)
                        {
                            margin-left: 5px;
                        }
                        // cursor: pointer;
                        // width: 30px;
                        // height: 30px;
                        // margin-right: 5px;
                        // border-radius: 4px;
                        // display: flex;
                        // align-items: center;
                        // justify-content: center;
                        // background-color: $themeGrey3;
                    }
                }
            }

        }

    }

}