.prod-switcher-outer {
    max-height: 80vh;
    max-height: 80dvh;
    overflow: auto;

    .dropdown-header
    {
        // font-weight: bold;
        // color: #383F45;
        // font-size: 1rem;
        font-weight: 600;
        /* color: #383F45; */
        /* font-size: 1rem; */
        padding: 5px 1rem 5px 1rem;

        &:not(:first-of-type) {
            margin-top: 10px;
        }
    }

    // &.show {

    //     .prod-switcher-grid {
    //         display: -ms-grid grid !important;
    //         display: grid !important;
    //     }
    // }

    &.prod-switcher-grid {
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 5px;
        // padding: 5px !important;
        
        // max-height: 50vh;
        // overflow: auto;

        // @media screen and (max-width: 767px) { 
        //     width: 100vw;
        // }

        & > .dropdown-item {
            padding: 5px;

            // @include desktop() {
            //     display: flex;
            //     flex-direction: column;
            //     justify-content: center;
            //     align-items: center;
            //     height: 110px;
            //     min-width: 110px;
            //     padding: 10px;
            //     box-sizing: border-box;
            //     // white-space: pre-wrap;
            //     text-align: center;
            //     border-radius: 4px;
            //     cursor: pointer;
            //     // min-width: 0;

                
            //     text-overflow: ellipsis;
            //     overflow: hidden;
            //     white-space: nowrap;
            // }

            // @include mobile()
            // {
            //     text-overflow: ellipsis;
            //     overflow: hidden;
            //     white-space: nowrap;
            // }

            // .product-title {

            //     @include desktop() {
            //         // white-space: nowrap;
            //         overflow: hidden;
            //         text-overflow: ellipsis;
            //         width: 100%;
            //         height: 40px;
            //         display: -webkit-box;
            //         -webkit-line-clamp: 2;
            //         -webkit-box-orient: vertical;
            //         font-size: 0.8rem;
            //     }
            // }
            
            // &:hover {
            //     .product-title {
            //         overflow: visible;
            //         white-space: pre-wrap;
            //         font-size: 10px;
            //         font-weight: 600;
            //     }
            // }

            .fs {

                // @include desktop() {
                //     width: 50px;
                //     height: 50px;
                //     display: flex;
                //     align-items: center;
                //     justify-content: center;
                //     font-size: 40px;
                // }

                // @include mobile()
                // {
                    width: 20px;
                    height: auto;
                    margin: 5px 10px 5px 0px;
                // }
            }

            svg {

                // @include desktop() {
                //     width: 50px;
                //     height: 50px;
                //     display: flex;
                //     align-items: center;
                //     justify-content: center;
                //     padding: 10px;
                // }

                // @include mobile()
                // {
                    width: 20px;
                    height: auto;
                    margin: 5px 10px 5px 0px;
                // }
            }
        }
    }
}