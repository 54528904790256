.view_container {

    .entries__container.grid {
        // height: 100%;
        // margin: 0 -15px;
        // padding: 20px;

        > .entries_item {
            // border-left: 2px solid transparent;
            border-left: 1px solid transparent;
            padding: 20px;
            min-height: 80%;
            // margin-bottom: 20px;
            margin: 0 15px;
            border: 1px solid $border-color;
            // @extend .border-bottom;

            @include desktop {
                margin: 0 20px;
            }

            &:not(:last-child)
            {
                margin-bottom: 40px;
            }

            &--active {
                // border: 2px solid theme-color("primary") !important;
                border: 1px solid darken($border-color, 20%) !important;
            }

            > .article_item {
                // padding-bottom: 20px;
                // margin-bottom: 20px;
                // @extend .border-bottom;
            }

        }

        // .entries_item {

            // &.entries_item--close {
            //     display: flex;
            //     padding: 5px 10px;
            //     cursor: pointer;
            //     user-select: none;
            //     @extend .border-bottom;

            //     // &:first-child {
            //     //     @extend .border-top;
            //     // }

            //     &:hover {
            //         background-color: #F5F5F5;
            //     }

            //     .entries_item {
            //         &__favorite {
            //             flex-shrink: 0;
            //             padding-right: 0.5rem;
            //             @extend .text-muted;
            //         }

            //         &__feed {
            //             flex-shrink: 0;
            //             width: 150px;
            //             @extend .text-truncate;
            //         }
            //         &__title {
            //             flex-grow: 1;
            //             // flex-shrink: 0;
            //             padding-right: 0.75rem;
            //             @extend .text-truncate;
            //         }
            //         &__desc {
            //             flex-grow: 3;
            //             padding-right: 0.75rem;
            //             @extend .text-truncate;
            //             @extend .text-muted;
            //         }
            //         &__time {
            //             flex-shrink: 0;
            //             padding-right: 0.75rem;
            //             @extend .text-muted;
            //         }
            //         &__link {
            //             flex-shrink: 0;
            //             @extend .text-muted;
            //         }
            //     }
            // }

        //     &.entries_item--open {
        //         padding: 5px 10px;
        //         border-left: 2px solid transparent;
        //         @extend .border-bottom;

        //         .entries_item {
        //             &__close {
        //                 float: right;
        //                 position: sticky;
        //                 // top: $reader-height;
        //                 top: 0;
        //                 margin-top: -5px;
        //                 margin-right: -10px;
        //                 background-color: #9E9E9E;
        //                 border-bottom-left-radius: 100%;
        //                 padding: 0px 4px 8px 10px;
        //                 color: white;
        //                 transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
        //                 cursor: pointer;

        //                 &:hover {
        //                     padding: 1px 4px 16px 20px;
        //                     background-color: #757575;
        //                 }
        //             }
        //             &__feed {
        //                 font-size: 1.2rem;
        //                 font-weight: bold;
        //                 @extend .text-primary;
        //             }
        //             &__secondary {
        //                 display: flex;
        //                 @extend .text-muted;

        //                 a {
        //                     @extend .text-muted;
        //                 }

        //                 .author {
        //                     padding-right: 0.5rem;
        //                 }
        //                 .writer {
        //                     padding-right: 0.5rem;
        //                 }
        //                 // .time {
                            
        //                 // }
        //             }
        //             &__share {
        //                 display: flex;
        //                 align-items: center;
        //                 margin-bottom: 1rem;

        //                 .item {
        //                     cursor: pointer;
        //                     margin: 5px 5px 5px 0;
        //                 }
        //                 .dropdown-toggle.dropdown-share {
        //                     padding: 0;
        //                     @extend .text-muted;

        //                     &::after {
        //                         display: none;
        //                     }
        //                 }
        //                 .btn {
        //                     padding: 0 0.25rem;
        //                 }
        //             }
        //             &__img {
        //                 height: auto;
        //                 width: auto;
        //                 max-height: 200px;
        //                 max-width: 100%;
        //                 object-fit: cover;
        //             }
        //             &__content {
        //                 margin-top: 0.75rem;
                        
        //                 img {
        //                     display: flex;
        //                     height: auto;
        //                     width: auto;
        //                     max-height: 200px;
        //                     max-width: 100%;
        //                     object-fit: contain;
        //                 }
        //                 // p {
        //                 //     margin: 0;
        //                 // }
        //             }
        //             &__visit {
        //                 text-align: center;
        //                 margin: 1rem 0;
        //             }
        //             &__footer {
        //                 display: flex;
        //                 align-items: center;

        //                 .item {
        //                     display: flex;
        //                     align-items: center;
        //                     padding: 0.25rem;
        //                     margin-right: 0.5rem;
        //                     cursor: pointer;
        //                     @extend .text-dark;

        //                     a {
        //                         text-decoration: none;
        //                         @extend .text-dark;
        //                     }

        //                     &__text {
        //                         padding-left: 0.25rem;
        //                     }
        //                 }

        //             }
        //         }

        //     }

        //     &.entries_item--active {
        //         border-left: 2px solid theme-color("primary");
        //     }

        //     &.entries_item--read {
        //         background-color: #FAFAFA;
        //         @extend .text-muted;

        //         .entries_item__feed {
        //             font-weight: normal !important;
        //         }
        //     }
    
        // }
    }
}