
.error {
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;

    .fof{
        display: table-cell;
        vertical-align: middle;

        h1{
            font-size: 50px;
            display: inline-block;
            padding-right: 12px;
            animation: fof_type .5s alternate infinite;
      }
  }
  
}



@keyframes fof_type{
	  from{box-shadow: inset -3px 0px 0px #888;}
	  to{box-shadow: inset -3px 0px 0px transparent;}
}