
.podcast_player--float {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1018;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 1.2rem;
    border: 1px solid $border-color;
    background: $white;
    cursor: pointer;
    @extend .shadow;

    @include desktop {
        bottom: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        padding: 10px;
        font-size: 1.6rem;
    }

    &.podcast_player--active {
        background: $primary;
        color: $white;
    }
} 

.podcast_player {
    z-index: $podcastPlaylerZindex;
    position: fixed;
    bottom: 0;
    background: $white;
    width: 100%;
    box-shadow: 0px -6px 20px #0000001a;
    display: flex;
    user-select: none;

    @include mobile
    {
        flex-direction: column;
    }

    .playback_container {
        cursor: default;
        display: flex;
        flex-direction: column;
        // transform: translate3d(1132px, -73px, 0px);
        z-index: 3;
        position: fixed;
        left: 50%;
        bottom: 116px;
        transform: translateX(-50%);
        // position: absolute;
        // top: 0px;
        // left: 0px;
        will-change: transform;
        border: 1px solid #ddd;
        background: #f8f9fa;
        padding: 20px 10px;
        -webkit-box-shadow: -10px 10px 13px -4px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: -10px 10px 13px -4px rgba(0,0,0,0.5);
        box-shadow: -10px 10px 13px -4px rgba(0, 0, 0, 0.5);
        z-index: 3;

        .close-btn {
            position: absolute;
            top: 0;
            right: 0;
            padding: 5px;
            cursor: pointer;
        }

        .playback_speed_btn {
            margin-bottom: 15px;
            display: flex;
            flex-wrap: nowrap;
            padding: 0 20px;

            & > .item {
                background-color: #fff;
                border: 1px solid #e7e7e7;
                padding: 5px;
                min-width: 10px;
                margin: 0 5px;
                cursor: pointer;

                &.active {
                    font-weight: 600;
                    border-color: #b2b5b8;
                }

                &:hover {
                    font-weight: 600;
                    border-color: #b2b5b8;
                }
            }
        }

        .playback_speed {
            margin-bottom: 5px;
            font-size: 18px;
            text-align: center;
        }

        .playback_range_container {
            width: 100%;

            .playback_range {
                -webkit-appearance: none;
                width: 100%;
                height: 2px;
                border-radius: 5px;
                background: $primary;
                outline: none;
                opacity: 0.7;
                -webkit-transition: .2s;
                transition: opacity .2s;
            }

        }
    }

    .slider {
        position: absolute;
        top: 0;
        width: 100%;
        height: 2px;

        .slider-track {
            background-color: #bebebe55;
            height: 2px;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
            cursor: pointer;
        }

        .buffer {
            background-color: #bddafe;
            height: 2px;
            position: absolute;
            top: 0;
            // width: 50%;
            width: 0%;
        }

        .seek {
            background-color: $primary;
            height: 2px;
            position: absolute;
            top: 0;
            // width: 30%;
            width: 0%;
        }

        .point {
            position: absolute;
            top: 1px;
            width: 10px;
            height: 10px;
            background: #2684fc;
            transform: translateY(-50%);
            border-radius: 50%;
            // left: 30%;
            left: 0%;
            z-index: 2;
        }
    }

    .podcast_player__header {
        display: flex;
        padding: 10px;

        @include mobile {
            border-bottom: 1px solid $border-color;
        }

        @include desktop {
            padding: 20px;
            width: 25%;
        }

        .podcast_player__seek-fixed {
            position: fixed;
            bottom: 0;
            height: 2px;
            background-color: $primary;
            left: 0;
            right: 0;
        }

        .podcast_player--album {
            width: 40px;
            height: 40px;
            object-fit: cover;

            @include desktop {
                width: 60px;
                height: 60px;
            }
        }

        .podcast_player__header__l {
            margin-right: 10px;

            @include desktop {
                margin-right: 20px;
            }
        }

        .podcast_player__header__m {
            @include mobile {
                flex-grow: 1;
            }
        }

        .podcast_player__header__r {

            @include desktop {
                display: none;
            }

            @include mobile {
                display: flex;
                align-items: center;
                width: 60px;
                justify-content: center;
                font-size: 120%;
            }

            .podcast_player--toggler {
                cursor: pointer;
            }
        }

        .podcast_player--title {
            font-weight: 600;
            color: $black;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            @include desktop {
                -webkit-line-clamp: 2;
            }
        }

        .podcast_player--time {
            margin-top: 5px;
        }

        .podcast_player--album {
            border-radius: 4px;
        }

    }

    .podcast_player__main {
        display: flex;
        justify-content: center;
        align-items: center;

        @include mobile{
            padding: 20px 20px;
            justify-content: space-between;
        }

        @include desktop {
            width: 50%;
        }

        & > .podcast_player--control-item {

            &.podcast_player--control-disabled 
            {
                color: #bebebe55;
            }

            &:not(.podcast_player--control-disabled)
            {
                cursor: pointer;
            }

            &.podcast_player--control-icon {
                font-size: 150%;

                @include desktop {
                    font-size: 200%;
                }
            }

            &:not(:last-child) {
                padding-right: 50px;
            }

            &:hover:not(.podcast_player--control-disabled) {
                color: $black;
            }
        }

    }

    .podcast_player__footer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;

        @include desktop {
            width: 25%;
        }

        @include mobile {
            padding: 20px 20px;
            justify-content: space-between;
        }

        .podcast_player--window {
            position: absolute;
            top: 5px;
            right: 20px;
            display: flex;

            & > .podcast_player--window-item {
                padding: 5px;
                cursor: pointer;

                &:hover {
                    color: $black;
                }

                &.podcast_player--window-close {
                    padding-right: 0px;
                }
            }
        }

        .podcast_player--volume__container {
            display: flex;
            align-items: center;

            .podcast_player--volume-icon {
                margin-right: 5px;
                font-size: 120%;
                cursor: pointer;
            }

            .podcast_player--volume-track {
                // height: 6px;
                display: flex;
                width: 100px;
                // background-color: #ccc;
                // display: inline-block;
                // cursor: pointer;

                .podcast_player--volume {
                    // height: 6px;
                    // width: 28px;
                    // background-color: $primary;
                    // position: absolute;
                    -webkit-appearance: none;
                    width: 100%;
                    height: 2px;
                    border-radius: 5px;
                    background: $primary;
                    outline: none;
                    opacity: 0.7;
                    -webkit-transition: .2s;
                    transition: opacity .2s;
                }

                &.podcast_player--volume-muted {

                    .podcast_player--volume {
                        background: #e5e5e8;
                    }
                }
            }
        }

        .podcast_player--duration__container {
            display: flex;
            align-items: center;
            margin-left: 20px;

            .podcast_player--duration_start {

            }

            .podcast_player--duration_mid {
                margin: 0 4px;
            }

            .podcast_player--duration_end {
                
            }
        }

    }

    .podcast_player__footer_2 {
        padding: 0 20px 10px 20px;
        display: flex;
        justify-content: space-around;

        & > .podcast_player--foot_window-item {
            background-color: #F5F5F5;
            padding: 5px 15px;
            border-radius: 30px;
        }
    }

    .podcast_player__tracker {
        position: relative;
        margin: 20px;
    }

    .podcast_player__tracker_duration {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        margin-top: -10px;
    }
}