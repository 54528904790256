.card__desc .card-text {
  height: 100%;
  max-height: 3rem;
  text-decoration: none;
  word-break: break-word;
  font-size: 0.875rem;
  color: #83878b;
}
.card__primary {
  margin-bottom: auto;
  height: 100%;
  flex-grow: inherit;
  gap: 0.75rem;
}
.card-img {
  height: 12.5rem;
  object-fit: cover;
  background-color: #d7d7dc;
}

.m-ttl {
  height: 100%;
  max-height: 3rem;
  margin-bottom: auto;
  // margin-bottom: max(0, 0.25rem);
  // margin-bottom: min(auto, 16px);
  // margin-bottom: clamp(0, auto, 16px);
  // margin-bottom: min(max(0, 2rem), 1rem);
}

.entry_item_card {
  min-height: 100% !important;
}
.card-time{
  gap:0.5rem;
}

