#help-inner {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    grid-gap: 1rem;

    @include mobile()
    {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    }

    .help-section {
        table {
            width: 100%;
            tbody {
                tr {
                    &:first-child {
                        th {
                            padding: 10px 0;
                        }
                    }
                }
            }
        }
        .key {
            text-align: right;
            font-weight: bold;
            padding-right: 0.25em;
            white-space: nowrap;
            width: 9rem;
            color: $primary;
        }
        .value {
            text-align: left;
            font-weight: normal;
            white-space: nowrap;
        }
    }
}