.modal.bt-sh {
    padding: 0 !important;

    &.fade {
        .modal-dialog {
            transform: translate(0, 100%);
        }
    }

    &.show {
        .modal-dialog {
            transform: none;
        }
    }

    .modal-header {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        align-items: center;

        // .closeModal {
        //     cursor: pointer;
        //     background: #95959D;
        //     border-radius: 50%;
        //     color: #fff;
        //     width: 16px;
        //     height: 16px;
        //     font-size: 10px;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;

        //     &:before {
        //         font-size: 80%;
        //     }
        // }
    }

    .modal-content {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .modal-footer {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .modal-body {
        padding: 0;
        max-height: calc(80vh - 50px);
        max-height: calc(80dvh - 50px);
        overflow-y: auto;
    }
    
    .modal-dialog {
        position: absolute;
        bottom: 0;
        margin: 0;
        width: 100%;
        max-width: 100%;
    }

}