// @for $j from 1 through 4 {
//     .fa-#{$j}x {
//         font-size: 100% * $j
//     }
// }



.fw-600 {
    font-weight: 600 !important;
}

.fw-500{
    font-weight: 500 !important;
}