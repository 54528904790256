/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $primary;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: darken($primary, 10%);
  }