@mixin mini {
    @media screen and (max-width: 374px) { @content; }
}

@mixin mobile {
    @media screen and (max-width: 991px) { @content; }
}

@mixin desktop {
    @media screen and (min-width: 992px) { @content; }
}