$font-path: "fonts/Noto_Sans"; 

@mixin font-face($name, $path: $font-path) {
    @font-face {
        font-family: "#{$name}";
        src: url("#{$path}/#{$name}.eot"); /* IE9 Compat Modes */
        src: local(""),
             url("#{$path}/#{$name}.woff2") format("woff2"), /* Super Modern Browsers */
             url("#{$path}/#{$name}.woff") format("woff"), /* Modern Browsers */
             url("#{$path}/#{$name}.ttf") format("truetype"), /* Safari, Android, iOS */
             url("#{$path}/#{$name}.otf") format("opentype")
    }
}

@include font-face("NotoSans-Regular");
@include font-face("NotoSans-Bold");