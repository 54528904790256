
.stepper {
    padding: 0;

    &.stepper-horizontal {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    &.stepper-horizontal li {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-transition: .5s;
        transition: .5s
    }

    &.stepper-horizontal li a .label {
        margin-top: .63rem
    }

    &.stepper-horizontal li:not(:last-child):after {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        height: 1px;
        // margin: .5rem 0 0 0;
        content: "";
        background-color: rgba(0, 0, 0, 0.1)
    }

    &.stepper-horizontal li:not(:first-child):before {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        height: 1px;
        // margin: .5rem 0 0 0;
        content: "";
        background-color: rgba(0, 0, 0, 0.1)
    }

    &.stepper-horizontal li.completed:after {
        background-color: $primary
    }
    &.stepper-horizontal li.completed:before {
        background-color: $primary
    }
    &.stepper-horizontal li.active:before {
        background-color: $primary
    }

    // &.stepper-horizontal li:hover {
    //     background-color: rgba(0, 0, 0, 0.06)
    // }

    // @media(max-width:47.9375rem) {
    //     &.stepper-horizontal {
    //         -webkit-box-orient: vertical;
    //         -webkit-box-direction: normal;
    //         -ms-flex-direction: column;
    //         flex-direction: column
    //     }
    //     &.stepper-horizontal li {
    //         -webkit-box-orient: vertical;
    //         -webkit-box-direction: normal;
    //         -ms-flex-direction: column;
    //         flex-direction: column;
    //         -webkit-box-align: start;
    //         -ms-flex-align: start;
    //         align-items: flex-start
    //     }
    //     &.stepper-horizontal li a .label {
    //         -webkit-box-orient: vertical;
    //         -webkit-box-direction: normal;
    //         -ms-flex-flow: column nowrap;
    //         flex-flow: column nowrap;
    //         -webkit-box-ordinal-group: 3;
    //         -ms-flex-order: 2;
    //         order: 2;
    //         margin-top: .2rem
    //     }
    //     &.stepper-horizontal li:not(:last-child):after {
    //         position: absolute;
    //         top: 3.75rem;
    //         left: 2.19rem;
    //         width: 1px;
    //         height: calc(100% - 40px);
    //         content: ""
    //     }
    // }

    &.stepper-horizontal>li:not(:last-of-type) {
        margin-bottom: 0 !important
    }

    li .circle {
        display: inline-block;
        width: 1.75rem;
        height: 1.75rem;
        // margin-right: 0.5rem;
        line-height: 1.7rem;
        color: #fff;
        text-align: center;
        background: rgba(0,0,0,0.38);
        border-radius: 50%;
    }

    li.completed .circle {
        background-color: lighten($color: $primary, $amount: 10) !important;
    }

    li.active .circle {
        background-color: $primary !important;
    }

    .stepper-heading {
        text-align: center;
    }

}


.stepper-heading {
    text-align: center;
    font-weight: 600;
}
.stepper-body {
    .features-container {
        @include mobile {
            padding: 10px 0;
        }        
        .header {
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.5;
        }
    }

    .features-wrapper {

        .header_item_2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            justify-content: center;

            svg {
                height: 100px;
                width: auto;
            }

            .tdays {
                font-size: 1.2rem;
                font-weight: 600;
                line-height: 1.5;
            }
        }

        .note_ext_trial {
            // padding: 20px;
            padding-bottom: 0px;
            font-size: 13px;
            color: $primary;
            text-align: center;
        }

        
        .features-card {
            .desc {
                color: $primary;
            }
        }
        // @media screen and (max-width: 767px) {
        @include mobile {
            &{
                grid-gap: 5px !important;
            }
            .header_item_2 {
                flex-direction: column;
            }
            .features-card {
                display: flex;
                align-items: center;
                background: none;
                box-shadow: none !important;
                padding: 0 10px !important;
                align-items: center;

                svg {
                    width: 20px;
                    height: auto;
                    margin-right: 10px;
                }
                .desc{margin-left:auto}
                .title {
                    margin-top: 0px !important;
                    margin-bottom: 0px !important;
                }
            }
            .note_ext_trial{   
                padding: 0px !important;
                padding-top: 15px !important;
                text-align: left !important;
                padding-left: 20px !important;
            }
            // .header_item_2{flex-direction:column;}
            // .features-card 
            
            // .step2 .features-wrapper{padding: 5px;}
            
            
            // .features-card .title{ margin: 10px 0;font-size: 14px;}
            // .extend_trial_section {
            //     padding: 70px 10px 10px 10px !important;
            // }
        }
    }
}
.stepper-action {
    display: flex;
    justify-content: center;
    & > .btn {
        &:not(:first-child)
        {
            margin-left: 10px;
        }
    } 
}