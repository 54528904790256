#root .announcement + .container-fluid {
    .main {
        .spotlight_container {
            &--main {
                // padding: 20px;
                height: calc(100vh - #{$navbarHeight} - #{$rsTopHeight} - #{$spotlightSearchHeight} - #{$spotlightTabHeight} - #{$announcementHeight}) !important;
                height: calc(100dvh - #{$navbarHeight} - #{$rsTopHeight} - #{$spotlightSearchHeight} - #{$spotlightTabHeight} - #{$announcementHeight}) !important;
            }
        }
    }
}

.main {
    background-color: $themeGrey;
    // background-color: $white;

    &.contained { 
        .spotlight-settings {
            max-width: $c2MaxWidth;
            margin: 0 auto;
            border-left: 1px solid $border-color;
            border-right: 1px solid $border-color;
        }

        .spotlight_container {
    
            &--search {
                max-width: $c2MaxWidth;
                margin: 0 auto;
                border-left: 1px solid $border-color;
                border-right: 1px solid $border-color;
            }

            &--tab {
                max-width: $c2MaxWidth;
                margin: 0 auto;
                border-left: 1px solid $border-color;
                border-right: 1px solid $border-color;
            }

            &--main {
    
                .spotlight_container--main_wrapper {
                    max-width: $c2MaxWidth;
                    margin: 0 auto;
                    border-left: 1px solid $border-color;
                    border-right: 1px solid $border-color;
                    background-color: white;
                    min-height: 100%;
                }
            }
        }
    }

    .spotlight-settings {
        position: sticky;
        top: 0;
        display: flex;
        // flex-direction: column;
        flex-direction: row;
        z-index: 1;
    //     justify-content: center;
    //     // margin: 0 -15px 15px -15px;
    //     padding: 0 15px;
        background-color: $white;
    
        .spotlight-settings__top {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 20px;
            height: $rsTopHeight;
            flex-grow: 1;
            // background-color: $white;
            @extend .border-bottom;

            .primary {
                font-size: 1.2rem;
                font-weight: 600;
                margin: -2px 0 4px 0;
            }

            .secondary {
                @include mobile {
                    display: none;
                }
            }
        }

        .spotlight-settings__right {
            display: flex;
            justify-content: center;
            align-items: center;
            // 

            @extend .border-bottom;
            
            .btn {
                margin-right: 20px;
            }
        }

        .spotlight-settings__bottom {
            display: flex;
            align-items: center;
            padding: 20px;
            height: $rsBottomHeight;
            @extend .border-bottom;

            .title {
                font-weight: bold;
            }
        }
    }

    .spotlight_container {
        // padding: 20px;
        
        // background-color: $themeGrey3;
        background-color: $white;
        // max-width: $c2MaxWidth;
        // margin: 0 auto;
        // border-left: 1px solid $border-color;
        // border-right: 1px solid $border-color;

        &--search {
            display: flex;
            align-items: center;
            height: $spotlightSearchHeight;
            padding: 10px 20px;

            background-color: $white;

            .input-group {
                // max-width: 600px;
                max-width: 800px;
                background-color: lighten($primary, 40%);

                .input-group-prepend {
                    .input-group-text {
                        border-right: 0px;
                        background-color: transparent;
                        border-color: transparent;
                    }

                }
                .form-control {
                    border-left: 0px;
                    border-right: 0px;
                    background-color: transparent;
                    border-color: transparent;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
                .input-group-append {
                    .clear {
                        color: $primary;
                        background-color: transparent;
                        border-color: transparent;
                        // border-color: #ced4da;
                        border-left: 0px;
                    }
                }
            }

            .autocomplete {
                position: absolute;
                top: 100%;
                background-color: $white;
                left: 0;
                right: 0;
                box-shadow: 0px 10px 20px #0000001a;
                z-index: 2;
                max-height: 70vh;
                overflow-y: auto;
                overflow-y: overlay;
                // scrollbar-gutter: stable both-edges;

                .autocomplete--header {
                    font-size: 0.8rem;
                    display: flex;
                    justify-content: space-between;
                    padding: 4px 10px;
                    // border-bottom: 1px solid $border-color;
                    
                    .title {
                        text-transform: uppercase;
                    }

                    .action {
                        text-transform: uppercase;
                        color: $primary;
                        cursor: pointer;
                    }
                }

                &.default {

                    & > .item {
                        
                        &:hover, &.active {
                            background-color: rgba(0, 0, 0, 0.1);
                        }

                        .label {
                            background: lighten($primary, 30%);;
                            margin-right: 10px;
                            padding: 0.2em 0.4em;
                            border-radius: 4px;
                            font-weight: 600;
                        }

                        .desc {

                        }
                    }

                }

                & > .item {
                    padding: 10px;
                    font-size: 0.8rem;
                    cursor: pointer;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                }

                .autocomplete--content {

                    & > .feed-item {
                        display: flex;
                        font-size: 0.8rem;
                        cursor: pointer;
                        align-items: center;

                        &:not(:last-child) {
                            border-bottom: 1px solid $border-color;
                        }

                        &:hover, &.active {
                            background-color: rgba(0, 0, 0, 0.1);
                        }

                        
                        .feed-item__img {
                            margin: 10px;
                        }

                        .feed-item__info {
                            margin: 10px;

                            .feed-item__info__name {
                                font-weight: 600;
                            }

                            .feed-item__info__dmn {
                            }

                            .feed-item__info__desc {
                                display: none;
                            }
                        }

                        .feed-item__followers {
                            margin: 10px;
                            flex-shrink: 0;
                            margin-left: auto;
                        }
                    }

                    & > .folder-item {
                        display: flex;
                        font-size: 0.8rem;
                        cursor: pointer;
                        align-items: center;

                        &:not(:last-child) {
                            border-bottom: 1px solid $border-color;
                        }

                        &:hover, &.active {
                            background-color: rgba(0, 0, 0, 0.1);
                        }
                        
                        .feed-item__img {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: $border-color;
                            padding: 10px;
                            font-size: 1.2rem;
                            margin: 10px;
                            width: 40px;
                        }

                        .feed-item__info {
                            margin: 10px;

                            .feed-item__info__name {
                                font-weight: 600;
                            }
                        }

                        .feed-item__followers {
                            margin: 10px;
                            flex-shrink: 0;
                            margin-left: auto;
                        }
                    }
                }
            }
        }

        &--tab {
            height: $spotlightTabHeight;
            // padding: 0 20px;
            background-color: $white;

            @extend .border-bottom;

            .spotlight-tab {
                display: flex;
                flex-direction: row;
                white-space: nowrap;
                overflow-x: auto;
                padding: 0 20px;

                & > .spotlight-tab--item {
                    padding-bottom: 5px;
                    // border-bottom: 2px solid transparent;
                    cursor: pointer;
                    font-weight: 600;
                    font-size: 14px;

                    &.active {
                        color: $primary;
                        // border-color: $primary;
                        -webkit-box-shadow: 0px -2px 0px $primary inset;
                        -moz-box-shadow: 0px -2px 0px $primary inset;
                        box-shadow: 0px -2px 0px $primary inset;
                    }

                    &:hover {
                        color: $primary;
                        // border-color: $primary;
                        -webkit-box-shadow: 0px -2px 0px $primary inset;
                        -moz-box-shadow: 0px -2px 0px $primary inset;
                        box-shadow: 0px -2px 0px $primary inset;
                    }

                    &:not(:last-child) {
                        margin-right: 20px;
                    }
                }
            }
            
        }

        &--main {
            // padding: 20px;
            height: calc(100vh - #{$navbarHeight} - #{$rsTopHeight} - #{$spotlightSearchHeight} - #{$spotlightTabHeight});
            height: calc(100dvh - #{$navbarHeight} - #{$rsTopHeight} - #{$spotlightSearchHeight} - #{$spotlightTabHeight});
            overflow-y: auto;
            overflow-y: overlay;
            scrollbar-gutter: stable both-edges;
            position: relative;
            // background-color: $themeGrey3;
            background-color: $white;

            .spotlight_container--main_wrapper {
                background-color: $white;

                .breadcrum {
                    display: flex;
                    flex-direction: row;
                    padding: 10px 20px;
                    position: sticky;
                    top: 0;
                    background: white;
                    @extend .border-bottom;

                    & > .item {
                        font-size: 1.2rem;
                        font-weight: 600;
                        display: flex;
                        align-items: center;

                        &:not(:last-child) {
                            padding-right: 5px;

                            &:after {
                                content: "\e909";
                                padding-left: 5px;
                                font-size: 0.8rem;
                                vertical-align: text-top;
                                @extend i.fs;
                                color: #686D72 !important;
                                
                            }
                        }
                    }
                }

                .spotlight-default {

                    .featured {
                        padding: 10px 20px;
                        margin-bottom: 20px;
                        // margin-top: 10px;

                        .title {
                            font-weight: 600;
                            margin-bottom: 10px;
                        }

                        .items-navigation {
                            display: flex;
                            // align-items: center;

                            .left {
                                display: none;
                                position: relative;
                                flex-shrink: 0;
                                width: 30px;
                                margin-right: 5px;
                                cursor: pointer;

                                @include desktop {
                                    display: block;
                                }

                                &:hover {
                                    background: $border-color;
                                }

                                .fs {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%) rotate(180deg)
                                }
                            }

                            .right {
                                display: none;
                                position: relative;
                                flex-shrink: 0;
                                width: 30px;
                                margin-left: 5px;
                                cursor: pointer;

                                @include desktop {
                                    display: block;
                                }

                                &:hover {
                                    background: $border-color;
                                }

                                .fs {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }

                            .items {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                overflow-x: auto;
                                overflow-x: overlay;
                                // scrollbar-gutter: stable both-edges;
                                flex-grow: 1;

                                @include desktop {
                                    padding-bottom: 15px;
                                }

                                & > .item {
                                    position: relative;
                                    cursor: pointer;
                                    flex-shrink: 0;

                                    .name {
                                        position: absolute;
                                        top: 0;
                                        padding: 10px;
                                        color: #fff;
                                        word-break: break-word;
                                        height: 100%;
                                        width: 100%;
                                        text-align: center;
                                        background: rgba(0, 0, 0, 0.2);
                                    }

                                    &:hover {
                                        .name {
                                            background: rgba(0, 0, 0, 0.5);
                                        }
                                    }

                                    .featured-img {
                                        width: 120px;
                                        height: 160px;
                                        object-fit: cover;
                                        background: $border-color;
                                        border-radius: 4px;
                                    }
                                    
                                    &:not(:last-child) {
                                        margin-right: 20px;
                                    }
                                }
                            }
                        }

                    }

                    .category {
                        padding: 10px 20px;
                        margin-bottom: 20px;

                        .category-title {
                            font-weight: 600;
                            font-size: 0.9rem;
                            margin-bottom: 10px;
                            text-transform: uppercase;
                        }

                        .category-wrapper {
                            width: 100%;
                            display: -ms-grid;
                            display: grid;
                            grid-gap: 20px;
                            -ms-grid-columns: 1fr;
                            grid-template-columns: 1fr;
                            margin-bottom: 20px;

                            @include desktop {
                                -ms-grid-columns: 1fr 1fr 1fr 1fr;
                                grid-template-columns: 1fr 1fr 1fr 1fr;
                            }

                            & > .item {
                                // width: 100%;
                                // flex-shrink: 0;
                                // margin-bottom: 20px;
                                cursor: pointer;
                                padding: 10px;
                                @extend .border;

                                &:hover {
                                    background: $border-color;
                                }

                                // &:not(:last-child) {
                                //     margin-right: 5px;
                                // }

                                .name {
                                    font-weight: 600;
                                    color: $dark;
                                    margin-bottom: 25px;
                                }

                                .feed {
                                    display: flex;
                                    align-items: center;

                                    .feed-img {
                                        width: 28px;
                                        height: 28px;
                                        object-fit: cover;
                                        background: $border-color;
                                        border-radius: 4px;
                                        flex-shrink: 0;
                                        margin-right: 10px;
                                    }

                                    .feed-name {
                                        font-size: 0.8rem;
                                    }
                                }
                            }
                        }

                    }

                    .packages {
                        

                        .indpkg__sub-heading {
                            padding: 10px 20px;
                            font-weight: 600;
                            font-size: 1rem;
                            text-transform: uppercase;
                            margin-top: 20px;

                            &.indpkg__sub-heading--small {
                                font-size: 0.7rem;
                                margin-top: 0px;
                            }
                        }

                        .indpkg__list-wrapper {
                            width: 100%;
                            display: -ms-grid;
                            display: grid;
                            grid-gap: 20px;
                            -ms-grid-columns: 1fr;
                            grid-template-columns: 1fr;
                            padding: 10px 20px;

                            @include desktop {
                                -ms-grid-columns: 1fr 1fr 1fr;
                                grid-template-columns: 1fr 1fr 1fr;
                            }

                            & > .indpkg__list-item {
                                display: flex;
                                justify-content: space-between;
                                border: 1px solid $border-color;
                                padding: 10px 10px;
                                border-radius: 4px;
                                align-items: center;

                                .indpkg__list-item--name {

                                }

                                .indpkg__list-item--btn {
                                    flex-shrink: 0;
                                    margin-left: 4px;
                                }
                            }
                        }
                    }
                }

                .content {
                    
                    .item-wrapper {

                        .empty-wrapper {
                            background-color: $white;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            text-align: center;
                            padding: 1rem;
                            height: calc(100vh - #{$navbarHeight} - #{$rsTopHeight} - #{$spotlightSearchHeight} - #{$spotlightTabHeight});
                            height: calc(100dvh - #{$navbarHeight} - #{$rsTopHeight} - #{$spotlightSearchHeight} - #{$spotlightTabHeight});

                            svg {
                                flex-shrink: 0;
                                width: 100px;
                                height: 100px;

                                @include desktop() {
                                    width: 200px;
                                    height: 200px;
                                }
                            }
                        }

                        & > .item--folder {
                            display: flex;
                            flex-direction: row;
                            padding: 20px;

                            &:not(:last-child) {
                                @extend .border-bottom;
                            }

                            .icon {
                                background: $border-color;
                                width: 3rem;
                                height: 3rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-right: 10px;

                                i.fs {
                                    font-size: 2em;
                                }
                            }

                            .mid {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: center;
                                flex-grow: 1;
                                
                                .folder_name {
                                    font-weight: 600;
                                    cursor: pointer;

                                }
                                .folder_count {
                                    color: $text-muted;
                                }
                            }

                            .right {
                                margin-left: 20px;
                                flex-shrink: 0;
                                
                                .follow-btn {
                                    white-space: nowrap;

                                    @include mobile {
                                        padding: 0.25rem;
                                    }

                                    .follow-text {
                                        display: none;

                                        @include desktop {
                                            display: inline-block;
                                        }
                                    }
                                }
                            }
                        }

                        & > .item--feed {
                            display: flex;
                            flex-direction: row;
                            padding: 20px;

                            &:not(:last-child) {
                                @extend .border-bottom;
                            }

                            .img {
                                flex-shrink: 0;

                                .feed_img {
                                    background: $border-color;
                                    width: 50px;
                                    height: 50px;
                                    object-fit: cover;
                                }

                            }

                            .data {
                                padding-left: 20px;
                                flex-grow: 1;
                                min-width: 0;

                                .top {
                                    display: flex;
                                    justify-content: space-between;
                                    margin-bottom: 6px;

                                    .left {

                                        .feed_name {
                                            font-weight: 600;
                                        }

                                        .feed_url {
                                            font-size: 0.8rem;
                                        }

                                    }

                                    .right {
                                        margin-left: 20px;
                                        flex-shrink: 0;
                                        
                                        .follow-btn {
                                            white-space: nowrap;

                                            @include mobile {
                                                padding: 0.25rem;
                                            }

                                            .follow-text {
                                                display: none;

                                                @include desktop {
                                                    display: inline-block;
                                                }
                                            }
                                        }
                                    }
                                }

                                .mid {
                                    margin-bottom: 6px;
                                    
                                    @include mobile {
                                        display: none !important;
                                    }

                                    // &.description {
                                    //     line-height: 1.6rem;
                                    //     display: -webkit-box;
                                    //     -webkit-line-clamp: 2;
                                    //     -webkit-box-orient: vertical;  
                                    //     overflow: hidden;
                                    // }

                                    &.description {
                                        line-height: 1.6rem;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;  
                                        overflow: hidden;
                                    }
                                }
                                
                                .bottom {
                                    display: flex;
                                    justify-content: space-between;
                                    
                                    @include mobile {
                                        display: none;
                                    }

                                    @include desktop {
                                        justify-content: flex-start;
                                    }

                                    & > .item {
                                        display: flex;
                                        align-items: center;
                                        font-size: 0.8rem;

                                        @include desktop {
                                            &:not(:last-child) {
                                                margin-right: 20px;
                                            }
                                        }

                                        .count {
                                            font-weight: 500;
                                            margin-right: 3px;
                                        }

                                        .title {

                                        }
                                    }
                                }
                            }
                        }

                        .related_folder--container {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            overflow-x: auto;
                            overflow-x: overlay;
                            background-color: lighten($primary, 40%);
                            @extend .border-bottom;

                            &.has-right {
                                mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0));
                                -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0));
                            }

                            &.has-left {
                                mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0));
                                -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0));
                            }

                            &.has-right.has-left {
                                 /* Combine the gradients */
                                -webkit-mask-image: 
                                linear-gradient(to right, black 90%, rgba(0, 0, 0, 0)), /* Fade right */
                                linear-gradient(to left, black 90%, rgba(0, 0, 0, 0)); /* Fade left */
                                -webkit-mask-composite: destination-in;
                                mask-composite: intersect;

                                /* Ensure compatibility with non-WebKit browsers */
                                mask-image: 
                                linear-gradient(to right, black 90%, rgba(0, 0, 0, 0)), 
                                linear-gradient(to left, black 90%, rgba(0, 0, 0, 0));
                            }

                            // &.has-right::after {
                            //     content: "";
                            //     position: absolute;
                            //     top: 0;
                            //     right: 0;
                            //     width: 30px;
                            //     height: 100%;
                            //     background: linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
                            //     pointer-events: none;
                            //   }
                              
                            //   &.has-left::before {
                            //     content: "";
                            //     position: absolute;
                            //     top: 0;
                            //     left: 0;
                            //     width: 30px;
                            //     height: 100%;
                            //     background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
                            //     pointer-events: none;
                            //   }
                        

                            & > .related_folder--item {
                                flex-shrink: 0;
                                display: flex;
                                max-width: 60vw;
                                padding: 12px;

                                @include mobile {
                                    font-size: 80%;
                                    padding: 0;
                                }

                                // @include desktop {
                                //     padding: 12px;
                                // }

                                .related_folder--item__icon {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-size: 2rem;
                                    padding: 8px;

                                    &:before {
                                        content: "\e922";
                                        font-family: 'feedspot' !important;
                                        background: #f8f8fa;
                                        width: 40px;
                                        height: 40px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }
                                }

                                .related_folder--item__data {
                                    padding: 8px;
                                    min-width: 0;

                                    .related_folder--item__title {
                                        cursor: pointer;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        font-weight: 600;

                                        &:hover {                                            
                                            color: $primary;
                                        }
                                    }

                                    .related_folder--item__count {

                                    }

                                    .related_folder--item__follow {
                                        padding: 0;
                                        font-size: inherit;
                                    }

                                }
                            }
                        }
                    }

                }
            }
        }
        
    }

}

.modal {

    .spotlight_container--folder {
        // padding: 20px;
        // height: calc(100vh - #{$navbarHeight} - #{$rsTopHeight} - #{$spotlightSearchHeight} - #{$spotlightTabHeight});
        // overflow-y: auto;
        // overflow-y: overlay;
        // position: relative;

        // .breadcrum {
        //     display: flex;
        //     flex-direction: row;
        //     padding: 10px 20px;
        //     position: sticky;
        //     top: 0;
        //     background: white;
        //     @extend .border-bottom;

        //     & > .item {
        //         font-size: 1.2rem;
        //         font-weight: 600;

        //         &:not(:last-child) {
        //             padding-right: 5px;

        //             &:after {
        //                 content: "\e901";
        //                 padding-left: 5px;
        //                 font-size: 0.8rem;
        //                 vertical-align: text-top;
        //                 @extend i.fs;
                        
        //             }
        //         }
        //     }
        // }

        // .spotlight-default {

        //     .featured {
        //         padding: 10px 20px;
        //         margin-bottom: 20px;

        //         .title {
        //             font-weight: 600;
        //             margin-bottom: 10px;
        //         }

        //         .items-navigation {
        //             display: flex;
        //             // align-items: center;

        //             .left {
        //                 display: none;
        //                 position: relative;
        //                 flex-shrink: 0;
        //                 width: 30px;
        //                 margin-right: 5px;
        //                 cursor: pointer;

        //                 @include desktop {
        //                     display: block;
        //                 }

        //                 &:hover {
        //                     background: $border-color;
        //                 }

        //                 .fs {
        //                     position: absolute;
        //                     top: 50%;
        //                     left: 50%;
        //                     transform: translate(-50%, -50%) rotate(180deg)
        //                 }
        //             }

        //             .right {
        //                 display: none;
        //                 position: relative;
        //                 flex-shrink: 0;
        //                 width: 30px;
        //                 margin-left: 5px;
        //                 cursor: pointer;

        //                 @include desktop {
        //                     display: block;
        //                 }

        //                 &:hover {
        //                     background: $border-color;
        //                 }

        //                 .fs {
        //                     position: absolute;
        //                     top: 50%;
        //                     left: 50%;
        //                     transform: translate(-50%, -50%);
        //                 }
        //             }

        //             .items {
        //                 display: flex;
        //                 flex-direction: row;
        //                 flex-wrap: nowrap;
        //                 overflow-x: auto;
        //                 overflow-x: overlay;
        //                 flex-grow: 1;

        //                 @include desktop {
        //                     padding-bottom: 15px;
        //                 }

        //                 & > .item {
        //                     position: relative;
        //                     cursor: pointer;
        //                     flex-shrink: 0;

        //                     .name {
        //                         position: absolute;
        //                         top: 0;
        //                         padding: 10px;
        //                         color: #fff;
        //                         word-break: break-word;
        //                         height: 100%;
        //                         width: 100%;
        //                         text-align: center;
        //                         background: rgba(0, 0, 0, 0.2);
        //                     }

        //                     &:hover {
        //                         .name {
        //                             background: rgba(0, 0, 0, 0.5);
        //                         }
        //                     }

        //                     .featured-img {
        //                         width: 120px;
        //                         height: 160px;
        //                         object-fit: cover;
        //                         background: $border-color;
        //                         border-radius: 4px;
        //                     }
                            
        //                     &:not(:last-child) {
        //                         margin-right: 20px;
        //                     }
        //                 }
        //             }
        //         }

        //     }

        //     .category {
        //         padding: 10px 20px;
        //         margin-bottom: 20px;

        //         .category-title {
        //             font-weight: 600;
        //             font-size: 0.9rem;
        //             margin-bottom: 10px;
        //             text-transform: uppercase;
        //         }

        //         .category-wrapper {
        //             width: 100%;
        //             display: -ms-grid;
        //             display: grid;
        //             grid-gap: 20px;
        //             -ms-grid-columns: 1fr;
        //             grid-template-columns: 1fr;
        //             margin-bottom: 20px;

        //             @include desktop {
        //                 -ms-grid-columns: 1fr 1fr 1fr 1fr;
        //                 grid-template-columns: 1fr 1fr 1fr 1fr;
        //             }

        //             & > .item {
        //                 // width: 100%;
        //                 // flex-shrink: 0;
        //                 // margin-bottom: 20px;
        //                 cursor: pointer;
        //                 padding: 10px;
        //                 @extend .border;

        //                 &:hover {
        //                     background: $border-color;
        //                 }

        //                 // &:not(:last-child) {
        //                 //     margin-right: 5px;
        //                 // }

        //                 .name {
        //                     font-weight: 600;
        //                     color: $dark;
        //                     margin-bottom: 25px;
        //                 }

        //                 .feed {
        //                     display: flex;
        //                     align-items: center;

        //                     .feed-img {
        //                         width: 28px;
        //                         height: 28px;
        //                         object-fit: cover;
        //                         background: $border-color;
        //                         border-radius: 4px;
        //                         flex-shrink: 0;
        //                         margin-right: 10px;
        //                     }

        //                     .feed-name {
        //                         font-size: 0.8rem;
        //                     }
        //                 }
        //             }
        //         }

        //     }
        // }

        .content {
            
            .item-wrapper {

                & > .item--folder {
                    display: flex;
                    flex-direction: row;
                    padding: 20px;

                    &:not(:last-child) {
                        @extend .border-bottom;
                    }

                    .icon {
                        background: $border-color;
                        width: 3rem;
                        height: 3rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;

                        i.fs {
                            font-size: 2em;
                        }
                    }

                    .mid {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        flex-grow: 1;
                        
                        .folder_name {
                            font-weight: 600;

                        }
                        .folder_count {
                            color: $text-muted;
                        }
                    }

                    .right {
                        margin-left: 20px;
                        flex-shrink: 0;
                        
                        .follow-btn {
                            white-space: nowrap;

                            @include mobile {
                                padding: 0.25rem;
                            }

                            .follow-text {
                                display: none;

                                @include desktop {
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }

                & > .item--feed {
                    display: flex;
                    flex-direction: row;
                    padding: 20px;

                    &:not(:last-child) {
                        @extend .border-bottom;
                    }

                    .img {
                        flex-shrink: 0;

                        .feed_img {
                            background: $border-color;
                            width: 50px;
                            height: 50px;
                            object-fit: cover;
                        }

                    }

                    .data {
                        padding-left: 20px;
                        flex-grow: 1;
                        min-width: 0;

                        .top {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 6px;

                            .left {

                                .feed_name {
                                    font-weight: 600;
                                }

                                .feed_url {
                                    font-size: 0.8rem;
                                }

                            }

                            .right {
                                margin-left: 20px;
                                flex-shrink: 0;
                                
                                .follow-btn {
                                    white-space: nowrap;

                                    @include mobile {
                                        padding: 0.25rem;
                                    }

                                    .follow-text {
                                        display: none;

                                        @include desktop {
                                            display: inline-block;
                                        }
                                    }
                                }
                            }
                        }

                        .mid {
                            margin-bottom: 6px;

                            // &.description {
                            //     line-height: 1.6rem;
                            //     display: -webkit-box;
                            //     -webkit-line-clamp: 2;
                            //     -webkit-box-orient: vertical;  
                            //     overflow: hidden;
                            // }

                            &.description {
                                line-height: 1.6rem;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;  
                                overflow: hidden;
                            }
                        }
                        
                        .bottom {
                            display: flex;
                            justify-content: space-between;

                            @include desktop {
                                justify-content: flex-start;
                            }

                            & > .item {
                                display: flex;
                                align-items: center;
                                font-size: 0.8rem;

                                @include desktop {
                                    &:not(:last-child) {
                                        margin-right: 20px;
                                    }
                                }

                                .count {
                                    font-weight: 500;
                                    margin-right: 3px;
                                }

                                .title {

                                }
                            }
                        }
                    }
                }
            }

        }
    }
}