$thumbsize: 120px;
$podcastPlaylistWidth: 540px;
$podcastPlaylistZindex: 1019;
$podcastPlaylerZindex: 1018;

@import './playlist';
@import './player';

#root .announcement + .container-fluid {
    .main.podcast {
        .main_container {
            height: calc(100vh - #{$navbarHeight} - #{$podcastHeight} - #{$announcementHeight}) !important;
            height: calc(100dvh - #{$navbarHeight} - #{$podcastHeight} - #{$announcementHeight}) !important;
        }
    }
}

.main.podcast {
    // background-color: $themeGrey;
    background-color: $themeGrey3;

    &.contained {

        .main-podcast {
            max-width: $c2MaxWidth;
            margin: 0 auto;
            border-left: 1px solid $border-color;
            border-right: 1px solid $border-color;
        }
        
        .main_container {

            .main_container-outer {
                max-width: $c2MaxWidth;
                margin: 0 auto;
                border-left: 1px solid $border-color;
                border-right: 1px solid $border-color;
            }
        }
    }

    .main-podcast {
        position: sticky;
        top: 0;
        background-color: $white;
        display: flex;
        flex-direction: column;
        z-index: 1;
    
        .main-podcast__top {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
            height: $podcastHeight;
            @extend .border-bottom;

            .primary {
                font-size: 1.2rem;
                font-weight: 600;
                display: flex;
                width: 100%;

                & > .bc-link {
                    // display: inline-flex;
                    display: flex;
                    align-items: center;

                    // > .fs {
                    //     &:before {
                    //     vertical-align: text-bottom;
                    //     }
                    // }

                    &.tt {
                        min-width: 0;

                        .tt-text {
                            @extend .text-truncate
                        }
                    }

                    .hide-mob {
                        @include mobile {
                            display: none;
                        }
                    }

                    .hide-desk {
                        @include desktop {
                            display: none;
                        }
                    }

                    &:not(.active) {
                        color: $primary;
                        cursor: pointer;
                    }

                    &:not(:last-child) {

                        &:after {
                            font-family: 'feedspot' !important;
                            speak: never;
                            font-style: normal;
                            font-weight: normal;
                            font-variant: normal;
                            text-transform: none;
                            line-height: 1;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            // content: "\e901";
                            content: "\e909";
                            padding-left: 5px;
                            padding-right: 5px;
                            font-size: 80%;
                            vertical-align: middle;
                            color: $themeGrey2;
                            padding-top: 2px;
                        }
                    }
                }
            }
        }
    }

    .main_container {
        background-color: $themeGrey3;
        height: calc(100vh - #{$navbarHeight} - #{$podcastHeight});
        height: calc(100dvh - #{$navbarHeight} - #{$podcastHeight});
        overflow-y: auto;
        overflow-y: overlay;
        // scrollbar-gutter: stable both-edges;
        position: relative;

        @extend .noselect;

        .main_container-outer {

            background-color: $white;
            min-height: 100%;

            .podcast-heading-wrapper {
                padding: 10px 20px;

                .podcast-heading{
                    font-size: 1.2rem;
                    font-weight: 600;
                }

            }

            .podcast-entry-heading-wrapper {
                padding: 10px 20px;

                .podcast-entry-wrapper {
                    display: flex;

                    .feed-img {
                        flex-shrink: 0;
                        margin-right: 20px;
                        width: 60px;
                        height: auto;
                        background-color: $themeGrey3;

                        @include desktop {
                            width: $thumbsize;
                        }
                    }

                    .right {
                        display: flex;
                        flex-direction: column;
                        width: 100%;

                        .top {
                            display: flex;
                            justify-content: space-between;

                            .feed-name {
                                font-size: 1.2rem;
                                font-weight: 500;
                            }

                            .feed-action {
                                margin-left: 5px;
                                flex-shrink: 0;

                                .follow-text {
                                    display: none;

                                    @include desktop {
                                        display: inline-flex;
                                    }
                                }
                            }
                        }

                        .feed-cat {
                            margin-top: 5px;
                        }

                        .feed-desc {
                            display: none;
                            margin-top: 10px;

                            @include desktop {
                                display: block;
                            }
                        }
                    }
                }

                .feed-desc-m {
                    display: block;
                    margin-top: 10px;

                    @include desktop {
                        display: none;
                    }
                }
            }

            // .podcast-folder-container {
            //     padding: 20px 20px;
            // }

            .podcast-categories-container {
                padding: 20px 20px;
                margin: 0 auto;
                width: 100%;
                display: -ms-grid;
                display: grid;
                grid-gap: 20px;
                -ms-grid-columns: 1fr 1fr 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr 1fr;

                @include mobile {
                    -ms-grid-columns: 1fr 1fr;
                    grid-template-columns: 1fr 1fr;
                }

                @include mini {
                    -ms-grid-columns: 1fr;
                    grid-template-columns: 1fr;
                }

                & > .podcast-categories__item {
                    display: flex;
                    align-items: center;
                    border: 1px solid $border-color;
                    border-radius: 4px;
                    padding: 10px;
                    cursor: pointer;

                    &:hover {
                        background-color: $border-color;
                    }

                    .cat_img {
                        width: 28px;
                        height: 28px;
                        object-fit: cover;
                        margin-right: 10px;
                    }

                    .cat_name {
                        font-weight: bold;
                    }
                }
            }

            .podcast-item-container {
                padding: 10px 20px;

                & > .podcast-item {

                    .heading-container {
                        display: flex;
                        margin: 0.5rem 0;
                        justify-content: space-between;
                        align-items: center;
                        text-transform: uppercase;

                        .heading {
                            font-size: 1rem;
                            font-weight: bold;
                            color: #333;
                        }

                        .heading-action {
                            cursor: pointer;
                            font-size: 1rem;
                            text-decoration: none;
                            color: $primary;
                            font-weight: bold;
                        }
                    }
                }
                
                // removed out for reusablity
                .podcast-slider-container {

                    @include desktop {
                        padding: 0 30px;
                        position: relative;
                    }

                    .next {
                        position: absolute;
                        right: 0;
                        top: calc(60px - 9px);
                        // top: 50%;
                        // transform: translateY(-50%);
                        cursor: pointer;

                        @include mobile {
                            display: none;
                        }

                    }

                    .prev {
                        position: absolute;
                        left: 0;
                        top: calc(60px - 9px);
                        // top: 50%;
                        // transform: translateY(-50%);
                        cursor: pointer;

                        @include mobile {
                            display: none;
                        }
                    }

                    .swiper-wrapper {
                        min-height: 150px;

                        & > .swiper-slide {
                            width: $thumbsize;

                            .podcast-slider-item {
                                // margin-right: 10px;
                                color: #333;
                                text-decoration: none;
                                cursor: pointer;

                                &:after {
                                    content:'\A';
                                    position:absolute;
                                    width:100%; height:100%;
                                    top:0; left:0;
                                    background:rgba(0,0,0,0.6);
                                    opacity:0;
                                    transition: all 0.5s;
                                    -webkit-transition: all 0.5s;
                                    height: $thumbsize;
                                }

                                .feed_image {
                                    width: 100%;
                                    margin-bottom: 10px;
                                    background-color: $themeGrey3;
                                }

                                .feed_name {
                                    font-size: 0.8rem;
                                }

                                .feed_action {
                                    display: none;
                                    position: absolute;
                                    width: 100%;
                                    height: 40px;
                                    top: calc(#{$thumbsize} - 40px);
                                    z-index: 100;
                                    background: transparent;
                                    font-size: 1rem;
                                    justify-content: space-around;
                                    align-items: center;
                                    color: #000;

                                    .feed_item {
                                        cursor: pointer;
                                        background: white;
                                        height: 26px;
                                        line-height: 26px;
                                        width: 26px;
                                        border-radius: 50%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        @extend .shadow;

                                        &.feed_play {

                                        }

                                        &.feed_follow {
                                            
                                        }

                                    }
                                }

                                &:hover {

                                    &:after {
                                        opacity:1;
                                    }

                                    .feed_image {
                                        // filter:grayscale(100%);
                                        // filter: blur(4px);
                                    }

                                    .feed_action {
                                        display: flex;
                                    }
                                }
                            }

                        }
                    }
                }
            }

            .podcast-feed-container {
                display: flex;
                flex-wrap: wrap;

                .podcast-feed-item {
                    width: $thumbsize;
                    // margin-right: 10px;
                    color: #333;
                    text-decoration: none;
                    cursor: pointer;
                    margin-left: 20px;
                    margin-top: 20px;
                    position: relative;

                    &:after {
                        content:'\A';
                        position:absolute;
                        width:100%; height:100%;
                        top:0; left:0;
                        background:rgba(0,0,0,0.6);
                        opacity:0;
                        transition: all 0.5s;
                        -webkit-transition: all 0.5s;
                        height: $thumbsize;
                    }

                    .feed_image {
                        width: 100%;
                        margin-bottom: 10px;
                        background-color: $themeGrey3;
                    }

                    .feed_name {
                        font-size: 0.8rem;
                    }

                    .feed_action {
                        display: none;
                        position: absolute;
                        width: 100%;
                        height: 40px;
                        top: calc(#{$thumbsize} - 40px);
                        z-index: 100;
                        background: transparent;
                        font-size: 1rem;
                        justify-content: space-around;
                        align-items: center;
                        color: #000;

                        .feed_item {
                            cursor: pointer;
                            background: white;
                            height: 26px;
                            line-height: 26px;
                            width: 26px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @extend .shadow;

                            &.feed_play {

                            }

                            &.feed_follow {
                                
                            }

                        }
                    }

                    &:hover {

                        &:after {
                            opacity:1;
                        }

                        .feed_image {
                            // filter:grayscale(100%);
                            // filter: blur(4px);
                        }

                        .feed_action {
                            display: flex;
                        }
                    }
                }

            }

            .podcast-entry-container {
                padding: 20px 20px;
                
                .podcast-entry-wrapper {
                    margin: 0 auto;
                    width: 100%;
                    display: -ms-grid;
                    display: grid;
                    grid-gap: 20px;
                    -ms-grid-columns: 1fr;
                    grid-template-columns: 1fr;

                    @include desktop {
                        -ms-grid-columns: 1fr 1fr 1fr 1fr;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                    }

                    & > .podcast-entry--item {
                        border: 1px solid $border-color;
                        padding: 10px;
                        display: flex;
                        flex-direction: column;
                        width: 100%;

                        .entry_date {
                            font-size: 0.8rem;
                        }
                        .entry_title {
                            font-weight: 600;
                            color: #383F45;
                            margin-top: 5px;
                            margin-bottom: 10px;

                            @include desktop {
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }

                        .action_container {  
                            display: flex;
                            margin-top: auto;
                            width: 100%;

                            .action--item {
                                display: flex;
                                align-items: center;

                                .fs {
                                    font-size: 1.4rem;
                                }
                                

                                &.action--play {
                                    color: $primary;
                                    cursor: pointer;

                                }

                                &.action--enqueue {
                                    color: $primary;
                                    cursor: pointer;
                                    margin-left: 10px;
                                    padding-top: 5px;

                                    &.action--active {
                                        opacity: 0.8;
                                        color: $success;
                                    }
                                }

                                &.action--time {
                                    margin-left: auto;

                                    .fs {
                                        font-size: 1.2rem;
                                        margin-right: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }

    }

}