$border-color-new: #fff;

.c1 {

    &.main {
        padding: 0;
        background-color: $white;
        // background-color: $themeGrey3;

        &.contained {
            .reader-settings {
                max-width: $c2MaxWidth;
                margin: 0 auto;
                border-left: 1px solid $border-color-new;
                border-right: 1px solid $border-color-new;
            }

            .view_container {
    
                .entries__container {
                    max-width: $c2MaxWidth;
                    margin: 0 auto;
                    border-left: 1px solid $border-color-new;
                    border-right: 1px solid $border-color-new;
                    margin-top: 10px;
                }

                .empty-wrapper {
                    max-width: $c2MaxWidth;
                    margin: 0 auto;
                    border-left: 1px solid $border-color-new;
                    border-right: 1px solid $border-color-new;
                }

                .initializer-wrapper {
                    max-width: $c2MaxWidth;
                    margin: 0 auto;
                    border-left: 1px solid $border-color-new;
                    border-right: 1px solid $border-color-new;
                }
            }
        }

        .reader-settings {
            position: sticky;
            top: 0;
            background-color: $white;
            display: flex;
            flex-direction: column;
            z-index: 1;
        //     justify-content: center;
        //     // margin: 0 -15px 15px -15px;
        //     padding: 0 15px;
        
            .reader-settings__top {
                display: flex;
                align-items: center;
                padding: 20px;
                height: $rsTopHeight;
                border-bottom: 1px solid $border-color-new;
                // @extend .border-bottom;

                @include mobile {

                    .btn {
                        font-size: 0.8rem;
                        padding: 0.25rem 0.5rem;
                    }
                }

                @include mini() {
                    // .reader--mark-btn {
                    //     display: none;
                    // }
                }

                .btn-refresh {
                    position: relative;
                    
                    .red-dot {
                            width: 8px;
                            height: 8px;
                            background: red;
                            z-index: 9999;
                            display: block;
                            border-radius: 50%;
                            position: absolute;
                            top: -4px;
                            right: -4px;
                    }
                }
            }

            .reader-settings__bottom {
                display: flex;
                align-items: center;
                padding: 20px;
                height: $rsBottomHeight;
                border-bottom: 1px solid $border-color-new;
                // @extend .border-bottom;

                .title {
                    font-weight: bold;
                    color: $themeSidebarBlack;
                }
            }
        }

        .view_container {
            background-color: $white;
            // background-color: $themeGrey3;

            .entries__container {
                min-height: 100%;
                background-color: $white;
                margin-top: 10px;

                > .entries_item  {
                    background-color: $white;
                }
            }
        }
    }
}