.view_container {

    .entries__container.list {
        // margin: 0 -15px;
        margin-left: -6px;

        > .entries_item {
            border-left: 2px solid transparent;

            &:first-child {
                border-top: 1px solid $border-color;
            }

            &.entries_item--mobile {
                display: flex;
                align-items: center;

                .entries_item__sec_wrapper {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    min-width: 0;

                    > .entries_item__sec_wrapper--item {
                        display: flex;

                        .entries_item {
                            &__favorite {
                            }
                            &__feed {
                            }
                            &__title {
                                font-size: 0.9rem;
                            }
                            &__desc {
                                font-size: 0.8rem;
                            }
                            &__time {
                                font-size: 0.8rem;
                            }
                            &__link {
                            }
                            &__share {
                            }
                        }
                    }

                }
            }

            &.entries_item--close {
                display: flex;
                padding: 5px 10px;
                cursor: pointer;
                user-select: none;
                position: relative;
                @extend .border-bottom;

                // &:first-child {
                //     @extend .border-top;
                // }

                &:hover {
                    background-color: #F5F5F5 !important;

                    .entries_item {
                        &__time {
                            display: none;
                        }
                        &__share {
                            display: flex;
                            flex-shrink: 0;
                            align-items: center;
                            margin-right: 5px;
                            // padding: 10px;
                            // border: 1px solid $border-color;
                            // font-size: 1.2rem;
                            // margin-bottom: 20px;
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            background-color: #F5F5F5 !important;
                            padding-right: 10px;
                            padding-left: 10px;
                    
                            .item {
                                width: 30px;
                                height: 12px;
                                line-height: 12px;
                                display: inline-block;
                                text-align: center;
                                color: $text-muted;
                            
                                &:hover {
                                    // color: #212529;
                                    // background-color: #d1d1d6;
                                }
                            }
                    
                            .item--scheduler {
                                border: 1px solid $themeGrey2;
                                font-size: 10px;
                                line-height: 12px;
                                padding: 2px 5px;
                                margin-left: 5px;
                                border-radius: 2px;
                                
                                &:hover {
                                    color: #212529;
                                    background-color: $themeGrey3;
                                    // background-color: #d1d1d6;
                                }
                            }
                        }
                    }
                }

                .entries_item {
                    &__favorite {
                        flex-shrink: 0;
                        padding-right: 0.5rem;

                        padding-left: 0.5rem;
                        padding-right: 1rem;
                        @extend .text-muted;

                        // @include desktop {
                        //     padding-left: 0.5rem;
                        //     padding-right: 0.5rem;
                        // }
                    }
                    &__podcast {
                        flex-shrink: 0;
                        padding-right: 0.5rem;
                        // @extend .text-muted;

                        + .entries_item__feed {
                            width: 125px;
                        }

                        @include desktop {
                            color: $text-muted;
                        }

                        @include mobile
                        {
                            margin-top: 4px;
                            // color: $primary;
                        }
                    }
                    
                    &__video {
                        flex-shrink: 0;
                        padding-right: 0.5rem;
                        // @extend .text-muted;

                        + .entries_item__feed {
                            width: 125px;
                        }

                        @include desktop {
                            color: $text-muted;
                        }

                        @include mobile
                        {
                            margin-top: 4px;
                            // color: $primary;
                        }
                    }

                    &__feed {
                        flex-shrink: 0;
                        width: 150px;
                        padding-right: 0.5rem;
                        color: $themeTitleBlack;
                        // @extend .text-truncate;
                    }
                    &__title {
                        // flex-grow: 1;
                        // flex-shrink: 0;
                        padding-right: 0.75rem;
                        font-weight: 600;
                        color:$themeTitleBlack;

                        @include mobile {
                            flex-grow: 1;
                        }

                        @include desktop {
                            flex-shrink: 0;
                            // max-width: 500px;
                            max-width: 50vw;
                            margin-right: auto;
                        }
                        // @extend .text-truncate;
                    }
                    &__desc {
                        flex-grow: 3;
                        padding-right: 0.75rem;
                        // @extend .text-truncate;
                        @extend .text-muted;
                    }
                    &__time {
                        flex-shrink: 0;
                        padding-right: 0;
                        @extend .text-muted;

                        @include desktop {
                            padding-right: 0.75rem;
                        }
                    }
                    &__link {
                        flex-shrink: 0;
                        @extend .text-muted;
                    }
                    &__share {
                        display: none;
                        @extend .text-dark;
                        
                        .item {
                            padding-right: 0.5rem;
                        }
                    }
                }

                &.entries_item--read {
                    background-color: #FAFAFA;
                    @extend .text-muted;

                    .entries_item {
                        &__title {
                            font-weight: normal;
                            color: inherit;
                        }
                        &__feed {
                            font-weight: normal;
                            color: inherit;
                        }
                    }
                }

            }

            &.entries_item--open {
                padding: 5px 10px;
                border-left: 2px solid transparent;
                @extend .border-bottom;

                .entries_item {
                    &__close {
                        float: right;
                        position: sticky;
                        // top: $reader-height;
                        top: 0;
                        margin-top: -5px;
                        margin-right: -10px;
                        background-color: #9E9E9E;
                        border-bottom-left-radius: 100%;
                        padding: 0px 4px 8px 10px;
                        color: white;
                        transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
                        cursor: pointer;

                        &:hover {
                            padding: 1px 4px 16px 20px;
                            background-color: #757575;
                        }
                    }
                    &__feed {
                        font-size: 1.2rem;
                        font-weight: bold;
                        @extend .text-primary;
                    }
                    &__secondary {
                        display: flex;
                        @extend .text-muted;

                        a {
                            @extend .text-muted;
                        }

                        .author {
                            padding-right: 0.5rem;
                        }
                        .writer {
                            padding-right: 0.5rem;
                        }
                        // .time {
                            
                        // }
                    }
                    &__share {
                        display: flex;
                        align-items: center;
                        margin-bottom: 1rem;

                        .item {
                            cursor: pointer;
                            margin: 5px 5px 5px 0;
                        }
                        .dropdown-toggle.dropdown-share {
                            padding: 0;
                            @extend .text-muted;

                            &::after {
                                display: none;
                            }
                        }
                        .btn {
                            padding: 0 0.25rem;
                        }
                    }
                    &__img {
                        height: auto;
                        width: auto;
                        max-height: 200px;
                        max-width: 100%;
                        object-fit: cover;
                    }
                    &__content {
                        margin-top: 0.75rem;

                        img {
                            display: flex;
                            height: auto;
                            width: auto;
                            max-height: 200px;
                            max-width: 100%;
                            object-fit: contain;
                        }
                        // p {
                        //     margin: 0;
                        // }
                    }
                    &__visit {
                        text-align: center;
                        margin: 1rem 0;
                    }
                    &__footer {
                        display: flex;
                        align-items: center;

                        .item {
                            display: flex;
                            align-items: center;
                            padding: 0.25rem;
                            margin-right: 0.5rem;
                            cursor: pointer;
                            @extend .text-dark;

                            a {
                                text-decoration: none;
                                @extend .text-dark;
                            }

                            &__text {
                                padding-left: 0.25rem;
                            }
                        }

                    }
                }

            }

            &.entries_item--active {
                border-left: 2px solid theme-color("primary");
            }

            &.entries_item--read {
                background-color: #FAFAFA;
                @extend .text-muted;

                .entries_item__feed {
                    font-weight: normal !important;
                }
            }
    
        }
    }
}