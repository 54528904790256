.notes-box{
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.notes{
  &-container{
    @extend .notes-box;
  }
  &-header{
    position: relative;
    padding: 14px 12px;
    padding-bottom: 8px;
    display: flex;
    column-gap: 12px;
    align-items: center;
    img{
      height: 1rem;
      width: 1rem;
      aspect-ratio: 1-1;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 1rem;
    }
    .autor{
      font-size: 0.875rem;
      margin-bottom: 0;
      color: $themeTitleBlack;
    }
    .timeago{
      font-size: 0.75rem;
      margin-bottom: 0;
      color: $themeGrey2;
    }
  }
  &-body{
    padding: 12px;
    padding-top: 0;
    p{
      margin-bottom: 0;      
      font-size: 0.875rem;
      color: $themeGrey2;
      padding-right: 0.75rem;
    }
  }
}

.view_more_notes_btn{
  font-size: 0.75rem;
  color: $themeGrey2;
}

.add-nots{
  &-container{
    @extend .notes-box;    
  }
  &-inner{
    .notes-header{
      padding-bottom: 12px;
    }

  }
  &-btn-box{
    padding: 0 12px 14px 12px;
  }
}


.notes-input{
  border: none;
  outline: none;
  width: 100%;
  font-size: 1rem;
  resize: none;
  &.px-nots{
    padding-inline: 12px;
    margin-top: 0 !important;
  }
}

.highlight-icon  {
  background-color: rgba(249, 216, 48, 0.4);
  border-radius: 2px;
  // content: "";
  // display: inline-block;
  height: 16px;
  margin-right: .5rem;
  // overflow: hidden;
  width:16px;
  
}

