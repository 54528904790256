// @mixin text-color-based-on-bg($bg-color) {
//     $lightness: lightness($bg-color);
//     color: if($lightness > 50%, black, white);
// }

.ss_header{
    // margin-bottom: 1.5rem;
    // padding-inline: 1rem;
    font-size: 1rem;
    font-weight: 600;
    color: $themeTitleBlack;
}

.ss_btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 5rem;
    min-width: 4rem;
    text-align: center;
    font-size: 13px;
    color: $themeTitleBlack;
    font-weight: 600;
}


.bg{
    &-typ{
        border-radius: 2.5rem;
        line-height: 2.5rem;
        height: 2.5rem;
        width: 2.5rem;
        font-size: 1.25rem;
        text-align: center;
        margin-bottom: .5rem;
        color: #fff;
    }
    &-mail{
        background: $mail;
        // @include text-color-based-on-bg($mail);
    }
    &-copy-link{
        background: $copy-link;
        // @include text-color-based-on-bg($copy-link);
    }
    &-dots{
        background: $dots;
        // @include text-color-based-on-bg($dots);
    }
    &-onenote {
        background: $onenote;
        // @include text-color-based-on-bg($onenote);
    }
    &-twitter,
    &-x-twitter {
        background: $twitter;
        // @include text-color-based-on-bg($twitter);
    }
    &-evernote {
        background: $evernote;
        // @include text-color-based-on-bg($evernote);
    }
    &-linkedin {
        background: $linkedin;
        // @include text-color-based-on-bg($linkedin);
    }
    &-pocket {
        background: $pocket;
        // @include text-color-based-on-bg($pocket);
    }
    &-facebook {
        background: $facebook;
        // @include text-color-based-on-bg($facebook);
    }
    &-buffer {
        background: $buffer;
        // @include text-color-based-on-bg($buffer);
    }
    &-wordpress {
        background: $wordpress;
        // @include text-color-based-on-bg($wordpress);
    }
    &-email {
        background: $email;
        // @include text-color-based-on-bg($email);
    }
    &-pintrest {
        background: $pintrest;
        // @include text-color-based-on-bg($pintrest);
    }
    &-instapaper {
        background: $instapaper;
        // @include text-color-based-on-bg($instapaper);
    }
    &-custom-sharing {
        background: $custom-sharing;
        // @include text-color-based-on-bg($custom-sharing);
    }
    &-google {
        background: $google;
        // @include text-color-based-on-bg($google);
    }
    &-blogger {
        background: $blogger;
        // @include text-color-based-on-bg($blogger);
    }
    &-reddit {
        background: $reddit;
        // @include text-color-based-on-bg($reddit);
    }
    &-tumblr {
        background: $tumblr;
        // @include text-color-based-on-bg($tumblr);
    }
    &-mastodon {
        background: $mastodon;
        // @include text-color-based-on-bg($mastodon);
    }
    &-diigo {
        background: $diigo;
        // @include text-color-based-on-bg($diigo);
    }
}