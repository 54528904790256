$sidebarTopHeight: 50px;
$sidebarBottomHeight: 7.4rem;//116px;
$sidebarBottomHeightExpanded: 15.4rem;//244px;

#root .announcement + .container-fluid {
    .c1 {
        // height: calc(100vh - #{$announcementHeight} - #{$navbarHeight}  - #{$rsTopHeight} - #{$rsBottomHeight}) !important;
        height: calc(100vh - #{$announcementHeight} - #{$navbarHeight}) !important;
        height: calc(100dvh - #{$announcementHeight} - #{$navbarHeight}) !important;

        &.main {
            .view_container {
                overflow: auto;
                overflow-y: overlay;
                // scrollbar-gutter: stable both-edges;
                height: calc(100vh - #{$announcementHeight} - #{$navbarHeight} - #{$rsTopHeight} - #{$rsBottomHeight});
                height: calc(100dvh - #{$announcementHeight} - #{$navbarHeight} - #{$rsTopHeight} - #{$rsBottomHeight});
            }
        }
    }

    .sidebar .section.section-main {
        height: calc(100vh - #{$announcementHeight} - #{$navbarHeight} - #{$sidebarTopHeight} - #{$sidebarBottomHeight});
        height: calc(100dvh - #{$announcementHeight} - #{$navbarHeight} - #{$sidebarTopHeight} - #{$sidebarBottomHeight});
        
        &.products--open {
            height: calc(100vh - #{$announcementHeight} - #{$navbarHeight} - #{$sidebarTopHeight} - #{$sidebarBottomHeightExpanded});
            height: calc(100dvh - #{$announcementHeight} - #{$navbarHeight} - #{$sidebarTopHeight} - #{$sidebarBottomHeightExpanded});
        }
    }
}

#root .announcement {
    height: $announcementHeight;
}

.c1 {
    height: calc(100vh - #{$navbarHeight});
    // overflow: auto;
    // overflow-y:overlay;
    overflow: hidden;

    // @include desktop {
    //     overflow: hidden;

    //     &:hover {
    //         overflow: auto;
    //         overflow-y:overlay;
    //     }
    // }

    &.main {
        height: inherit;

        .view_container {
            overflow: auto;
            overflow-y: overlay;
            scrollbar-gutter: stable both-edges;
            height: calc(100vh - #{$navbarHeight} - #{$rsTopHeight} - #{$rsBottomHeight});
            height: calc(100dvh - #{$navbarHeight} - #{$rsTopHeight} - #{$rsBottomHeight});
            background-color: $white;

            .empty-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                height: inherit;
                flex-direction: column;
                text-align: center;
                padding: 1rem;
                background-color: $white;

                svg {
                    flex-shrink: 0;
                    width: 100px;
                    height: 100px;

                    @include desktop() {
                        width: 200px;
                        height: 200px;
                    }
                }
            }
        }

    }
}



.main {
    min-width: 0;

    .sidebar-toggle {
        // position: fixed;
        position: absolute;
        background-color: $themeGrey;
        z-index: 3;
        @extend .border-bottom;
        cursor:pointer;

        justify-content: center;
        line-height: 16px;
        height: 16px;
        padding: 0 2.5px;
        display: flex;
        align-items: center;
        color: #686D72;
        font-size: 10px;

        @include mobile {
            display: none;
        }

        &:hover {
            background-color: darken($themeGrey, 10%);
        }

        &.left {
            left: 0;
            @extend .border-right;

            i {
                transform: rotateY(0deg);
            }
        }

        &.right {
            right: 0;
            @extend .border-left;

            i {
                transform: rotateY(180deg);
            }
        }
    }

    
    &.l-active {
        @include mobile {
            pointer-events: none;
            overflow: hidden;

            &:before {
                content: '';
                background: rgba(0, 0, 0, 0.8);
                z-index: 3;
                width: 100%;
                display: block;
                height: 100%;
                position: fixed;
            }
        }

        .sidebar-toggle {
            &.left {
                // left: $sidebarWidth;
                left: 0;
                i {
                    transform: rotateY(180deg);
                }
            }
        }
    }
    
    &.r-active {
        .sidebar-toggle {
            &.right {
                // right: $sidebarWidth;
                right: 0;
                i {
                    transform: rotateY(0deg);
                }
            }
        }
    }
}

@keyframes sidebar_in {
    from {
        transform: translate(-100%, 0);
    }
    to {
        transform: translate(0%, 0);
    }
}

.sidebar {
    background-color: $themeGrey;
    margin: 0;
    padding: 0;
    user-select: none;

    &.left {
        @extend .border-right;
        width: $sidebarWidth;

        @include mobile {
            position: absolute;
            z-index: 4;
            width: 100%;
            // max-width: $sidebarWidth;
            max-width: 80%;
            // transform: translate(-100%, 0);
            // transition: transform 0.3s ease-out;
            animation-iteration-count: 1;
            animation: sidebar_in 0.3s normal forwards ease-out;
            -webkit-animation: sidebar_in 0.3s normal forwards ease-out; 
            -moz-animation:    sidebar_in 0.3s normal forwards ease-out;
            -o-animation:      sidebar_in 0.3s normal forwards ease-out;
        }

        // .sidebar-toggle {
        //     left: #{$sidebarWidth};
        //     @extend .border-right;
        // }

        // svg {
        //     transform: rotate(180deg);
        // }
    }
    &.right {
        user-select: auto;
        width: $sidebarWidth;
        @extend .border-left;

        // .sidebar-toggle {
        //     right: #{$sidebarWidth};
        //     @extend .border-left;
        // }
    }

    .section {
        padding: 10px 0px 10px 8px;

        &:not(:last-child) {
            @extend .border-bottom;
        }

        &.section-product {
            display: flex;
            align-items: center;
            height: $sidebarTopHeight;
            position: relative;

            .product-primary {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-right: 8px;
                width: 100%;

                .product-name {
                    font-weight: 600;
                }
            }
        }

        &.section-others {
            height: $sidebarBottomHeight;
            display: flex;
            align-items: flex-start;
            overflow: auto;
            overflow-y: overlay;
            // scrollbar-gutter: stable both-edges;

            &.products--open {
                height: $sidebarBottomHeightExpanded;
            }

            .l-0 {

                li {
                    // padding-left: 20px !important;
    
                    &:hover {
                        svg {
                            @extend .text-light;
                        }
                    }

                    svg {
                        @extend .text-primary;

                        width: 14px;
                        height: 14px;
                        margin-right: 10px;
                        margin-left: 10px;
                        // padding: 0 4px;
                    }

                    i {
                        width: 14px;
                        height: 14px;
                        margin-right: 6px;
                    }

                    .title {
                        font-weight: 500;
                    }

                    .s-icon {
                        padding-left: 10px;
                        padding-right: 5px;

                        &.off {
                            margin-top: 5px;
                        }
                    }
                }
            }
        }

        &.section-main {
            flex-grow: 1;
            height: calc(100vh - #{$navbarHeight} - #{$sidebarTopHeight} - #{$sidebarBottomHeight});
            height: calc(100dvh - #{$navbarHeight} - #{$sidebarTopHeight} - #{$sidebarBottomHeight});
            overflow: auto;
            overflow-y: overlay;
            // scrollbar-gutter: stable both-edges;

            &.products--open {
                height: calc(100vh - #{$navbarHeight} - #{$sidebarTopHeight} - #{$sidebarBottomHeightExpanded});
                height: calc(100dvh - #{$navbarHeight} - #{$sidebarTopHeight} - #{$sidebarBottomHeightExpanded});
            }
        }

        .empty-wrapper {
            border-radius: 0;
            background: #F9D8304D;
            // background: linear-gradient(90deg, #d2deff, transparent);
            padding: 10px 5px;
            text-align: center;
            font-size: 80%;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            min-width: 0;
            width: 100%;

            &.l-0 {
                margin-left: -8px;
                width: calc(100% + 8px);

                > li {
                    padding-left: 8px;
                    padding-right: 0px;
                    // margin-right: 4px;

                    &:not(.root){
                        height: 2rem;
                        margin-right: 8px;
                    }

                    &.main {
                        // margin-right: 8px;
                    }

                    > .title {
                        font-weight: 600;
                        font-size: 90%;
                        color: $themeSidebarBlack;
                    }
                }
            }

            &.l-1 {
                margin-left: -8px;
                width: calc(100% + 8px);

                > li {
                    padding-left: 20px;
                    padding-right: 0px;
                    // margin-right: 4px;

                    &:not(.root){
                        height: 2rem;
                        margin-right: 8px;
                    }

                    > .title {
                        font-weight: 500;
                        font-size: 90%;
                    }

                    &.has--unread {
                        color: $themeSidebarBlack;
                        font-weight: 600;

                        .s-count {
                            color: inherit;
                        }

                        .title {
                            font-weight: inherit;
                        }
                    }
                }
            }
            
            &.l-2 {
                margin-left: -20px;
                width: calc(100% + 20px);

                > li {
                    padding-left: 28px;
                    padding-right: 0px;

                    &:not(.root){
                        height: 2rem;
                        margin-right: 8px;
                    }

                    > .title {
                        font-weight: 400;
                        font-size: 80%;
                    }

                    &.has--unread {
                        color: $themeSidebarBlack;
                        font-weight: 600;

                        .s-count {
                            color: inherit;
                        }

                        .title {
                            font-weight: inherit;
                        }
                    }
                    // enable if want to removve gap l-2
                    // .icon {
                    //     margin-left: 0px;
                    //     padding-left: 0px;
                    // }
                }
            }

            li {
                display: flex;
                align-items: center;
                padding-right: 4px;
                padding-top: 5px;
                padding-bottom: 5px;
                
                
                .s-more {
                    @include desktop {
                        display: none;
                    }
                }

                &.active--keyboard {
                    &:not(.root) {
                        border: 1px solid $primary;
                        border-radius: 0;
                        background-color: $white;
                        color: $primary;
                    }
                }
                
                &:hover:not(.root):not(.no-hover) {
                    background-color: darken($secondary, 5%);
                    border-radius: 0 16px 16px 0;
                    color: #333;
                    cursor: pointer;
                    margin-right: 8px;

                    .icon {
                        color: inherit;
                    }
                    .s-icon {
                        color: inherit;
                    }
                    .e-icon {
                        color: inherit;
                    }
                    .title {
                        color: inherit;
                    }
                    .s-count {
                        // @include desktop {
                        //     display: none;
                        // }
                    }
                    .s-more {
                        @include desktop {
                            display: flex;
                        }
                    }
                }

                // drag drop hove color
                border-radius: 0 16px 16px 0;

                &.active {
                    background-color: $secondary;
                    margin-right: 8px;
                    // border-radius: 0 16px 16px 0;
                    color: #333;
                    .icon {
                        color: inherit;
                    }
                    .s-icon {
                        color: inherit;
                    }
                    .e-icon {
                        color: inherit;
                    }
                    .title {
                        color: inherit;
                    }
                }

                // &:not(:last-child) {
                //     margin-bottom: 8px;
                // }
                
                .title {
                    flex-grow: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor: pointer;

                    &:hover {
                        color: $dark;
                    }
                }

                .icon {
                    color: $primary;
                    margin-left: 10px;
                    padding: 0 4px;
                }

                .s-icon {
                    font-size: 10px;
                    color: $themeGrey2;
                    cursor: pointer;

                    & + .icon {
                        margin-left: 0px;
                    }

                    &.active {
                        .fs {
                            transform: rotate( 90deg );
                        }
                    }
                }

                .e-icon {
                    // font-size: 10px;
                    // padding: 0 10px;
                    font-size: 14px;
                    padding: 0 6px;
                    color: $themeGrey2;
                    cursor: pointer;

                    &.e-hov {

                        &:hover {
                            display: flex;
                            align-items: center;
                            background-color: darken($secondary, 10%);
                            border-radius: 50%;
                            margin-top: -5px;
                            margin-bottom: -5px;
                            height: 2rem;
                        }
                    }

                    &.s-count {
                        font-size: 12px;
                    }
                }

                img.fs-feed {
                    display: block;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: $border-color;
                }
                .expanded {
                    & + li.root {
                        padding-top: 0px;
                        padding-bottom: 0px;
                    }
                }

                &.dragging {
                    height: 0px !important;
                    
                    display: flex;
                    flex-direction: row;

                    &:before, &:after{
                        content: "";
                        flex: 1 1;
                        border-bottom: 1px solid;
                        border-color: $border-color;
                        margin: auto;
                    }

                    &.on {
                        &:before, &:after {
                            border-color: darken($primary, 10%);
                        }
                    }

                    &:hover {
                        background-color: transparent;
                        cursor: default;
                    }
                    
                    // &:before {
                    //     margin-right: 10px
                    // }
                    // &:after {
                    //     margin-left: 10px
                    // }
                }
            }
        }
    }
}

// .sidebar {
//     height: calc(100vh - 6.3rem + #{$nav-scroller-height});
//     overflow-y: auto;
//     overflow-x: hidden;
//     user-select: none;
//     padding: 0 15px;
//     &.top {
//         height: calc(100vh - 6.3rem);
//     }
//     &.modal-body {
//         padding: 0;
//     }
// }

// .main {
//     display: flex;
//     flex-direction: column;
//     height: calc(100vh - 6.3rem + #{$nav-scroller-height});
//     overflow-y: auto;
//     overflow-x: hidden;
//     &.top {
//         height: calc(100vh - 6.3rem);
//     }
// }

// .draggable-demo{
//     // padding: 0 20px;
//     .draggable-container {
//     //   margin: 10px 30px;
//     //   width: 300px;
//     //   width: 100%;
//     //   height: 200px;
//       overflow: auto;
//     //   border: 1px solid #ccc;
//     }
// }

// .rc-tree {
//     margin: 0;
//     border: 1px solid transparent;
// }
// .rc-tree-focused:not(.rc-tree-active-focused) {
//     border-color: cyan;
// }
// .rc-tree .rc-tree-treenode {
//     margin: 0;
//     padding: 0;
//     line-height: 24px;
//     white-space: nowrap;
//     list-style: none;
//     outline: 0;
//     display: flex;
//     flex-direction: row;
//     width: 100%;
//     align-items: center;
// }
// .rc-tree .rc-tree-treenode .draggable {
//     color: #333;
//     -moz-user-select: none;
//     -khtml-user-select: none;
//     -webkit-user-select: none;
//     user-select: none;
//     -khtml-user-drag: element;
//     -webkit-user-drag: element;
// }
// .rc-tree .rc-tree-treenode.drag-over > .draggable {
//     color: white;
//     background-color: #316ac5;
//     border: 1px #316ac5 solid;
//     opacity: 0.8;
// }
// .rc-tree .rc-tree-treenode.drag-over-gap-top > .draggable {
//     border-top: 2px blue solid;
// }
// .rc-tree .rc-tree-treenode.drag-over-gap-bottom > .draggable {
//     border-bottom: 2px blue solid;
// }
// .rc-tree .rc-tree-treenode.filter-node > .rc-tree-node-content-wrapper {
//     color: #a60000 !important;
//     font-weight: bold !important;
// }
// .rc-tree .rc-tree-treenode ul {
//     margin: 0;
//     padding: 0 0 0 18px;
// }
// .rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper {
//     // display: inline-block;
//     // height: 17px;
//     margin: 0;
//     padding: 1px 3px 0 0;
//     text-decoration: none;
//     vertical-align: top;
//     cursor: pointer;
//     display: flex;
//     align-items: center;
// }
// .rc-tree .rc-tree-treenode .rc-tree-switcher-noop + .rc-tree-node-content-wrapper {
//     width:100%;
// }
// .rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper {
//     width: calc(100% - 16px);
// }
// .rc-tree .rc-tree-treenode .rc-tree-indent + .rc-tree-switcher-noop + .rc-tree-node-content-wrapper {
//     width: calc(100% - 16px);
// }
// .rc-tree .rc-tree-title {
//     flex-grow: 1;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }
// .rc-tree .rc-tree-treenode span.rc-tree-switcher, .rc-tree .rc-tree-treenode span.rc-tree-checkbox, .rc-tree .rc-tree-treenode span.rc-tree-iconEle {
//     display: inline-block;
//     width: 16px;
//     height: 16px;
//     margin-right: 2px;
//     line-height: 16px;
//     vertical-align: middle;
//     background-color: transparent;
//     background-image: url('#{$CDN_FS_IMG}fs2/icons/subscription-icons.png');
//     background-repeat: no-repeat;
//     background-attachment: scroll;
//     border: 0 none;
//     outline: none;
//     cursor: pointer;
//     flex-shrink: 0;
// }
// .rc-tree .rc-tree-treenode span.rc-tree-switcher.rc-tree-icon__customize, .rc-tree .rc-tree-treenode span.rc-tree-checkbox.rc-tree-icon__customize, .rc-tree .rc-tree-treenode span.rc-tree-iconEle.rc-tree-icon__customize {
//     background-image: none;
// }
// .rc-tree .rc-tree-treenode span.rc-tree-icon_loading {
//     margin-right: 2px;
//     vertical-align: top;
//     background: url('data:image/gif;base64,R0lGODlhEAAQAKIGAMLY8YSx5HOm4Mjc88/g9Ofw+v///wAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQFCgAGACwAAAAAEAAQAAADMGi6RbUwGjKIXCAA016PgRBElAVlG/RdLOO0X9nK61W39qvqiwz5Ls/rRqrggsdkAgAh+QQFCgAGACwCAAAABwAFAAADD2hqELAmiFBIYY4MAutdCQAh+QQFCgAGACwGAAAABwAFAAADD1hU1kaDOKMYCGAGEeYFCQAh+QQFCgAGACwKAAIABQAHAAADEFhUZjSkKdZqBQG0IELDQAIAIfkEBQoABgAsCgAGAAUABwAAAxBoVlRKgyjmlAIBqCDCzUoCACH5BAUKAAYALAYACgAHAAUAAAMPaGpFtYYMAgJgLogA610JACH5BAUKAAYALAIACgAHAAUAAAMPCAHWFiI4o1ghZZJB5i0JACH5BAUKAAYALAAABgAFAAcAAAMQCAFmIaEp1motpDQySMNFAgA7') no-repeat scroll 0 0 transparent;
// }
// .rc-tree .rc-tree-treenode span.rc-tree-switcher.rc-tree-switcher-noop {
//     cursor: auto;
//     display: none; // val
// }
// .rc-tree .rc-tree-treenode span.rc-tree-switcher.rc-tree-switcher_open {
//     background-position: -93px -56px;
//     flex-shrink: 0;
// }
// .rc-tree .rc-tree-treenode span.rc-tree-switcher.rc-tree-switcher_close {
//     background-position: -75px -56px;
//     flex-shrink: 0;
// }
// .rc-tree .rc-tree-treenode span.rc-tree-checkbox {
//     width: 13px;
//     height: 13px;
//     margin: 0 3px;
//     background-position: 0 0;
//     flex-shrink: 0;
// }
// .rc-tree .rc-tree-treenode span.rc-tree-checkbox-checked {
//     background-position: -14px 0;
//     flex-shrink: 0;
// }
// .rc-tree .rc-tree-treenode span.rc-tree-checkbox-indeterminate {
//     background-position: -14px -28px;
//     flex-shrink: 0;
// }
// .rc-tree .rc-tree-treenode span.rc-tree-checkbox-disabled {
//     background-position: 0 -56px;
//     flex-shrink: 0;
// }
// .rc-tree .rc-tree-treenode span.rc-tree-checkbox.rc-tree-checkbox-checked.rc-tree-checkbox-disabled {
//     background-position: -14px -56px;
//     flex-shrink: 0;
// }
// .rc-tree .rc-tree-treenode span.rc-tree-checkbox.rc-tree-checkbox-indeterminate.rc-tree-checkbox-disabled {
//     position: relative;
//     background: #ccc;
//     border-radius: 3px;
// }
// .rc-tree .rc-tree-treenode span.rc-tree-checkbox.rc-tree-checkbox-indeterminate.rc-tree-checkbox-disabled::after {
//     position: absolute;
//     top: 5px;
//     left: 3px;
//     width: 5px;
//     height: 0;
//     border: 2px solid #fff;
//     border-top: 0;
//     border-left: 0;
//     -webkit-transform: scale(1);
//     transform: scale(1);
//     content: ' ';
// }
// .rc-tree:not(.rc-tree-show-line) .rc-tree-treenode .rc-tree-switcher-noop {
//     background: none;
// }
// .rc-tree.rc-tree-show-line .rc-tree-treenode:not(:last-child) > ul {
//     background: url('data:image/gif;base64,R0lGODlhCQACAIAAAMzMzP///yH5BAEAAAEALAAAAAAJAAIAAAIEjI9pUAA7') 0 0 repeat-y;
// }
// .rc-tree.rc-tree-show-line .rc-tree-treenode:not(:last-child) > .rc-tree-switcher-noop {
//     background-position: -56px -18px;
// }
// .rc-tree.rc-tree-show-line .rc-tree-treenode:last-child > .rc-tree-switcher-noop {
//     background-position: -56px -36px;
// }
// .rc-tree-child-tree {
//     display: none;
// }
// .rc-tree-child-tree-open {
//     display: block;
// }
// .rc-tree-treenode-disabled > span:not(.rc-tree-switcher), .rc-tree-treenode-disabled > a, .rc-tree-treenode-disabled > a span {
//     color: #767676;
//     cursor: not-allowed;
// }
// .rc-tree-treenode-active {
//     background: rgba(0,0,0,0.1);
// }
// // .rc-tree-node-selected {
// //     background-color: #ffe6b0;
// //     border: 1px #ffb951 solid;
// //     opacity: 0.8;
// // }
// .rc-tree-node-selected {
//     background-color: #ffffff;
//     border: 1px #343a40 solid;
//     color: #333;
//     padding: 5px !important;
// }
// .rc-tree-icon__open {
//     margin-right: 2px;
//     vertical-align: top;
//     background-position: -110px -16px;
// }
// .rc-tree-icon__close {
//     margin-right: 2px;
//     vertical-align: top;
//     background-position: -110px 0;
// }
// .rc-tree-icon__docu {
//     margin-right: 2px;
//     vertical-align: top;
//     background-position: -110px -32px;
// }
// .rc-tree-icon__customize {
//     margin-right: 2px;
//     vertical-align: top;
// }
// .rc-tree-indent-unit {
//     display: inline-block;
//     padding-left: 18px;
// }


// .customize-icon {
//     display: inline-block;
//     position: relative;
//     background: #2F54EB;
//     border-radius: 3px;
//     box-sizing: border-box;
//     width: 12px;
//     height: 12px;
//     vertical-align: top;
  
//     &:before {
//       content: '';
//       position: absolute;
//       left: 2px;
//       right: 2px;
//       top: 2px;
//       bottom: 2px;
//       background: #FFF;
//       border-radius: 100%;
//     }
  
//     &.sub-icon {
//       background: #FF4D4F;
//     }
  
//     &.selected-icon {
//       background: green;
//     }
  
//     &.feed-icon {
//       background: brown;
//     }
  
//     &.folder-icon {
//       background: yellow;
//     }
// }

// .has-bg-icon {
//     background-position: unset;
//     flex-shrink: 0;
// }

// .rc-tree .rc-tree-treenode span.rc-tree-iconEle.icon_tag {
//     background-image: url('#{$CDN_FS_IMG}fs2/icons/subscription-icons-2.png');
//     background-position: 0 -46px;
// }

// .rc-tree .rc-tree-count {
//     font-size: 80%;
// }

// .rc-tree-treenode {

//     .rc-tree-edit {
//         display: none;
//         width: 16px;
//         height: 16px;
//         margin-left: 2px;
//         line-height: 16px;
//         vertical-align: middle;
//         background-color: transparent;
//         background-repeat: no-repeat;
//         background-attachment: scroll;
//         border: 0 none;
//         outline: none;
//         cursor: pointer;
//         background-image: url('#{$CDN_FS_IMG}fs2/icons/subscription-icons-2.png');
//         background-position: 0 -73px;
//         flex-shrink: 0;
//     }

//     &:hover {
//         background-color: #f8f9fa;

//         .rc-tree-edit {
//             display: inline-block;
//         }

//         .rc-tree-count {
//             display: none;
//         }
//     }

// }

// .sidebar {
//     .subscription-action {
//         border: none;
//         border-radius: 0;

//         .list-group-item {
//             padding: 0px;
//             border: none;

//             &.active {
//                 color: #333;
//                 border: 1px solid !important;
//                 background-color: #ffffff;
//                 border-color: #343a40;
//                 padding: 5px 5px !important;

//                 &:focus {
//                     outline: none;
//                 }
//             }
//         }

//         .subscription-pinboards {
//             & > .item {
//                 display: flex;
//                 align-items: center;

//                 .icon {
//                     flex-shrink: 0;
//                 }

//                 .title {
//                     flex-grow: 1;
//                     margin-left: 5px;
//                     @extend .text-truncate;
//                 }

//                 .action {
//                     display: none;
//                     flex-shrink: 0;

//                     & > .item:not(:first-child) {
//                         margin-left: 5px;
//                     }
//                 }

//                 &:hover {

//                     .action {
//                         display: flex;
//                     }
//                 }
//             }
//         }
//     }
    
// }