.podcast_playlist {
    position: fixed;
    right: 0;
    top: $navbarHeight;
    width: $podcastPlaylistWidth;
    max-width: 100%;
    background: $white;
    z-index: $podcastPlaylistZindex;
    @extend .shadow;
    user-select: none;

    .podcast_playlist__header {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        justify-content: space-between;
        border-bottom: 1px solid $border-color;
    }

    .podcast_playlist__container {
        height: 100%;
        max-height: 50vh;
        overflow: auto;
        min-width: 0;

        .empty-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;
            text-align: center;

            svg {
                height: 100px;
                width: auto;
            }
        }

        .playlist-wapper {
            width: 100%;

            & > .playlist__item {
                display: flex;
                padding: 5px 10px;

                &.playlist__item--active {
                    background-color: lighten($primary, 40%);

                    .playlist__item--action {
                        color: $primary;
                    }
                }

                .playlist__item--action {
                    flex-shrink: 0;
                    cursor: pointer;
                }

                .playlist__item--title {
                    flex-grow: 1;
                    margin-right: auto;
                    cursor: pointer;
                }

                .playlist__item--duration {
                    flex-shrink: 0;
                }

                .playlist__item--download {
                    flex-shrink: 0;
                    cursor: pointer;
                }

                .playlist__item--remove {
                    flex-shrink: 0;
                    cursor: pointer;
                }
            }
        }

    }

    .podcast_playlist__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        border-top: 1px solid $border-color;

        .playlist_action {
            
            .playlist_action--shuffle {
                cursor: pointer;
                color: $text-muted;

                &.playlist_action--shuffle-active {
                    color: $primary;
                }
            }

            .playlist_action--loop {
                cursor: pointer;
                color: $text-muted;

                &.playlist_action--loop-active {
                    color: $primary;
                }
            }
        }
        .settings_action {

        }

    }

}