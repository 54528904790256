.payments_modal {
    .total-container {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        font-weight: 600;
        border: 1px solid #dee2e6 !important;
        margin: 1rem 1rem 0 1rem;
    }

    .trial_dates {
        color: #343a40;
        font-weight: 500;
    }

    .payments_checkout__charge {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .payments_checkout__charge--plan {
            font-weight: bold;
            line-height: 2.5rem;
            font-size: 1.2rem;
            text-transform: uppercase;
        }

        .payments_checkout__charge--cycle {
            text-transform: uppercase;
        }

        .payments_checkout__charge--total {
            text-transform: uppercase;
            line-height: 2.5rem;
            font-size: 1.2rem;
            display: flex;
            width: 100%;
            padding: 0 1rem;
            justify-content: space-between;
            background: #f5f5f5;
            margin-top: 1rem;

            .payments_checkout__charge--total-amount {
                font-weight: bold;
            }
        }
    }

    .payments_checkout {
        padding: 1rem;

        .payments_checkout_split {
            max-width: $pageWidth;
            width: 100%;
            margin: 0.8rem auto;
            text-align: center;

        }

        .payments_checkout_item {
            max-width: $pageWidth;
            width: 100%;
            margin: 0 auto;

            &.payments_checkout--stripe {
                
            .card {
                background-color: white;
                padding: 10px 20px 11px;
                border-radius: 5px;
                width: 100%;
                border: 1px solid #afafaf;
                box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
            }

            .pay-button{
                padding: 0.7rem 2rem;
                width: 100%;
                margin: 1rem 0;
                color: white;
                font-weight: bold;
                font-size: medium;
                background-color: $primary;
                border: 0;
                border-radius: 5px;
                box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
                transition: box-shadow 500ms;
                cursor: pointer;
                    
                &:disabled{
                    background-color: #afafaf;
                    box-shadow: none;
                    cursor: default;
                }
                &:disabled:hover{
                    box-shadow: none;
                }
                &:hover{
                    box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
                }
            }
                
            }

            &.payments_checkout--paypal {

            }
        }

        
    }
}