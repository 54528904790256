.feedback_modal {
    .rating-wrapper {
        font-size: 2rem;
    }
    .rating-wrapper:hover>.rating-item {
        .fs:before {
            content: "\e921";
        }
    }
    .rating-wrapper>.rating-item:hover {        
        .fs-favourite:before {
            content: "\e921";
        }
    }
    .rating-wrapper .rating-item:hover~.rating-item {
        .fs-favourite:before {
            content: "\e920";
        }
    }
}



.feedback-sticky {
    cursor: pointer;
    position: absolute;
    right: 0;
    display: inline-flex;
    align-items: center;
    top: 50%;
    z-index: 1019;
    background: $primary;
    color: #fff;
    text-orientation: mixed;
    padding: 12px 5px;
    writing-mode: vertical-lr;
    transform: scale(-1, -1) translateY(50%);
    -webkit-transform: scale(-1, -1) translateY(50%);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15) !important;

    &:hover {
        background: darken($primary, 5%);
        box-shadow: 0 1rem 3rem rgba(0,0,0,0.18) !important
    }

    @include mobile {
        display: none;
    }
}