$border-color-new: #fff;

@import "./organize";

#root .announcement + .container-fluid {
    .main.settings {
        .main_container {
            height: calc(100vh - #{$navbarHeight} - #{$settingsHeight} - #{$announcementHeight}) !important;
            height: calc(100dvh - #{$navbarHeight} - #{$settingsHeight} - #{$announcementHeight}) !important;
        }
    }
}

.main.settings {
    // background-color: $themeGrey;
    // background-color: $themeGrey3;
    background-color: $white;

    &.contained {

        .main-settings {
            max-width: $c2MaxWidth;
            margin: 0 auto;
            border-left: 1px solid $border-color-new;
            border-right: 1px solid $border-color-new;
        }
        
        .main_container {

            .main_container-outer {
                max-width: $c2MaxWidth;
                margin: 0 auto;
                border-left: 1px solid $border-color-new;
                border-right: 1px solid $border-color-new;
            }
        }
    }

    .main-settings {
        position: sticky;
        top: 0;
        background-color: $white;
        display: flex;
        flex-direction: column;
        z-index: 1;
    
        .main-settings__top {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 8px 20px;
            height: $settingsHeight;
            border-bottom: 1px solid $border-color-new;
            // @extend .border-bottom;

            .primary {
                font-size: 1.2rem;
                font-weight: 600;
                display: flex;

                & > .bc-link {
                    // display: inline-flex;
                    display: flex;
                    align-items: center;

                    // > .fs {
                    //     &:before {
                    //     vertical-align: text-bottom;
                    //     }
                    // }

                    .hide-mob {
                        @include mobile {
                            display: none;
                        }
                    }

                    .hide-desk {
                        @include desktop {
                            display: none;
                        }
                    }

                    &:not(.active) {
                        color: $primary;
                        cursor: pointer;
                    }

                    &:not(:last-child) {

                        &:after {
                            font-family: 'feedspot' !important;
                            speak: never;
                            font-style: normal;
                            font-weight: normal;
                            font-variant: normal;
                            text-transform: none;
                            line-height: 1;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            // content: "\e901";
                            content: "\e909";
                            padding-left: 5px;
                            padding-right: 5px;
                            font-size: 80%;
                            vertical-align: middle;
                            color: $themeGrey2;
                            padding-top: 2px;
                        }
                    }
                }
            }
        }
    }

    .main_container {
        // background-color: $themeGrey3;
        background-color: $white;
        height: calc(100vh - #{$navbarHeight} - #{$settingsHeight});
        height: calc(100dvh - #{$navbarHeight} - #{$settingsHeight});
        overflow-y: auto;
        overflow-y: overlay;
        scrollbar-gutter: stable both-edges;
        position: relative;

        .main_container-outer {

            background-color: $white;
            min-height: 100%;
            
            .grid_wrapper {
                width: 100%;
                display: -ms-grid;
                display: grid;
                -ms-grid-columns: 1fr 1fr;
                grid-template-columns: 1fr 1fr;
                grid-gap: 20px;
                padding: 20px;

                @include desktop {
                    -ms-grid-columns: 1fr 1fr 1fr 1fr;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                }

                & > .grid_item {
                    padding: 10px;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    @extend .border;

                    &:hover {
                        background-color: $themeGrey;
                    }

                    .title {
                        font-weight: 500;
                        margin-bottom: auto;
                    }
                    .icon {
                        margin-top: 20px;
                        font-size: 150%;
                    }
                }
            }

            .main_container_wrapper {
                padding: 20px;

                .form-group {
                    @include mobile() {
                        margin-bottom: 3rem;
                    }
                }
            }

            .pad-section {
                padding: 20px;
            }
        }
    }

    .level {
        
        &.level-4 {
            .change-email-btn {
                border-bottom: 1px dotted #686D72;
            }
        }
        
        &.level-5 {
            .change-email-btn {
                border-bottom: 1px dotted #686D72;
            }
        }
    }

}