
header.navbar {

    .input-group {
        
        .form-control {
            // border-left: 0px;
            // border-right: 0px;
            background-color: transparent;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .autocomplete {
            position: absolute;
            top: 100%;
            background-color: $white;
            left: 0;
            right: 0;
            box-shadow: 0px 10px 20px #0000001a;
            z-index: 2;
            max-height: 70vh;
            max-height: 70dvh;
            overflow-y: auto;
            overflow-y: overlay;
            // scrollbar-gutter: stable both-edges;
            color: #686D72;

            @include mobile() {
                position: fixed;
                top: $navbarHeight;
                max-height: calc(100vh - #{$navbarHeight});
                max-height: calc(100dvh - #{$navbarHeight});
            }

            .autocomplete--header {
                font-size: 0.8rem;
                display: flex;
                justify-content: space-between;
                padding: 4px 10px;
                // border-bottom: 1px solid $border-color;
                
                .title {
                    text-transform: uppercase;
                }

                .action {
                    text-transform: uppercase;
                    color: $primary;
                    cursor: pointer;
                }
            }

            &.default {

                & > .item {

                    .label {
                        background: lighten($primary, 30%);;
                        margin-right: 10px;
                        padding: 0.2em 0.4em;
                        border-radius: 4px;
                        font-weight: 600;
                    }

                    .desc {

                    }
                }

            }

            & > .item {
                padding: 10px;
                font-size: 0.8rem;
                cursor: pointer;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }

            .autocomplete--content {

                & > .feed-item {
                    display: flex;
                    font-size: 0.8rem;
                    cursor: pointer;
                    align-items: center;

                    &:not(:last-child) {
                        border-bottom: 1px solid $border-color;
                    }

                    &:hover, &.active {
                        background-color: rgba(0, 0, 0, 0.1);
                    }

                    
                    .feed-item__img {
                        margin: 10px;
                    }

                    .feed-item__info {
                        margin: 10px;

                        .feed-item__info__name {
                            font-weight: 600;
                        }

                        .feed-item__info__dmn {
                        }

                        .feed-item__info__desc {
                            display: none;
                        }
                    }

                    .feed-item__followers {
                        margin: 10px;
                        flex-shrink: 0;
                        margin-left: auto;
                    }
                }

                & > .folder-item {
                    display: flex;
                    font-size: 0.8rem;
                    cursor: pointer;
                    align-items: center;

                    &:not(:last-child) {
                        border-bottom: 1px solid $border-color;
                    }

                    &:hover, &.active {
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                    
                    .feed-item__img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: $border-color;
                        padding: 10px;
                        font-size: 1.2rem;
                        margin: 10px;
                        width: 40px;
                    }

                    .feed-item__info {
                        margin: 10px;

                        .feed-item__info__name {
                            font-weight: 600;
                        }
                    }

                    .feed-item__followers {
                        margin: 10px;
                        flex-shrink: 0;
                        margin-left: auto;
                    }
                }
            }
        }

    }
}