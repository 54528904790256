/* The following block can be included in a custom.scss */

/* make the customizations */
// $theme-colors: (
//     "info": tomato,
//     "danger": teal
// );

$body-color: $themeGrey2;

$primary: #2684FC;
$secondary: #E5E5E8;
$warning: #F9D830;
$danger: #DC5960;
$success: #107643;

// btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius
// $btn-line-height: 14px;
// $btn-font-size: 0.85rem;
// $btn-padding-x: 18px;
// $btn-padding-y: 7px;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";