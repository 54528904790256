.change_plan_modal {

    .plan_container {
        display: flex;
        flex-direction: column;

        @include desktop()
        {
            flex-direction: row;
        }
        
        & > .item {
            flex: 1;

            label {
                cursor: pointer;
                font-size: 0.8rem;
            }
        }
    }

}