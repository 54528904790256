.article_item {

    &--read {

        // .article_item {
        //     &__primary {
        //         font-weight: normal;
        //     }
        // }
    }

    &__primary {
        display: block;
        font-size: 1.5rem;
        margin-bottom: 5px;
        color: #383F45;
        font-weight: 500;
    }

    &__secondary {
        display: flex;
        font-size: 0.85rem;
        margin-bottom: 20px;
        flex-wrap: wrap;

        & > div:not(:last-child) {
            padding-right: 5px;
        }
    }

    &__sharing_wrapper {
        display: flex;
        align-items: center;
        padding: 10px;
        border: 1px solid $border-color;
        font-size: 1.2rem;
        margin-bottom: 20px;

        .item {
            // width: 30px;
            // height: 30px;
            // line-height: 30px;
            padding: 0.25rem;
            margin-right:0.25rem;
            display: inline-block;
            text-align: center;
        
            &:hover {
                color: #212529;
                // background-color: #d1d1d6;
            }
        }

        .item--scheduler {
            border: 1px solid $themeGrey2;
            font-size: 10px;
            line-height: 15px;
            padding: 2px 5px;
            margin-left: 5px;
            border-radius: 2px;
            
            &:hover {
                color: #212529;
                background-color: $themeGrey3;
                // background-color: #d1d1d6;
            }
        }
    }

    &__podcast {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }

    &__desc {
        font-size: 1.2rem;
        line-height: 1.8rem;
        margin-bottom: 20px;

        .c1 {
            height: auto;
        }

        img {
            // display: block;
            // margin: 10px auto;
            // background: #dee2e6;
            // // width: auto;
            // height: auto;
            // max-width: 100%;
            // max-height: 300px;
            // object-fit: contain;

            display: block;
            background: #dee2e6;
            object-fit: contain;
            max-width: 100%;
            height: auto;
            margin: 0 0 15px 0;
        }

        iframe {
            height: 350px !important;
            width: 100%;
        }

        .video-wrapper {
            position: relative;
            width: 100%;
            cursor: pointer;

            & + .modal-video iframe {
                height: 100% !important;
                width: 100% !important;
            }

            .desc-video {
                width: 100% !important;
                height: auto;
                filter: brightness(40%);
                -webkit-filter: brightness(40%);
            }

            .video-play {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 100px;
                color: $white;
            }
        }
        

        .toolbar-img-wrapper {
            position: relative;

            &:hover {
                .toolbar{
                    display: flex !important;
                }
            }

            .toolbar {
                position: absolute;
                top: 0;
                left: 0;
                align-items: center;
                padding: 5px;
                // border: 1px solid $border-color;
                font-size: 1.2rem;
                // margin-bottom: 20px;
                background-color: rgba(0 ,0 ,0 ,0.6);
                color: $white;
                display: none;

                // @include mobile {
                //     display: flex !important;
                // }

                .item {
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    display: inline-block;
                    text-align: center;
                    border: 1px solid transparent;
                
                    &:hover {
                        border: 1px solid $white;
                        // color: #212529;
                        // background-color: #d1d1d6;
                    }
                }
            }
        }
    }

    &__visit {
        font-size: 0.8rem;
        text-align: center;
        margin-bottom: 20px;
    }

}