$side-width: 640px;
$modal-header-height: 40px;

.modal.spotlighteModal {
    &.right {

        &.fade {
            .modal-dialog {
                transform: translate(100%, 0);
            }
        }

        &.show {
            .modal-dialog {
                transform: none;
            }
        }

        .modal-dialog {
            position: fixed;
            right: 0;
            // top: $navbarHeight;
            margin: 0;
            padding: 0;
            width: 100%;
            // max-width: $side-width;
            // width: 70vw;
            max-width: 100%;
            background: #fff;

            @include desktop()
            {
                width: 70vw;
            }

            .modal-content {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                border-width: 0px;

                .modal-header {
                    height: $modal-header-height;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;

                    .action__bar {
                        width: 100%;
                        max-width: 100%;
                        margin: 0 auto;

                        @include desktop()
                        {
                            width: $side-width;
                        }
                    }
                }

                .modal-body {
                    // height: calc(100vh - #{$modal-header-height} - #{$navbarHeight});
                    height: calc(100vh - #{$modal-header-height});
                    height: calc(100dvh - #{$modal-header-height});
                    overflow-y: auto;

                    .article_item {
                        width: 100%;
                        max-width: 100%;
                        margin: 0 auto;

                        @include desktop()
                        {
                            width: $side-width;
                        }

                        .empty-wrapper {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: inherit;
                            flex-direction: column;
                            text-align: center;
                            padding: 1rem;
                            background-color: $white;
            
                            svg {
                                flex-shrink: 0;
                                width: 100px;
                                height: 100px;
            
                                @include desktop() {
                                    width: 200px;
                                    height: 200px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
// 	&.left .modal-dialog,
// 	&.right .modal-dialog {
// 		position: fixed;
// 		margin: auto;
//         width: $side-width;
//         max-width: 100%;
// 		height: 100%;
// 		-webkit-transform: translate3d(0%, 0, 0);
// 		    -ms-transform: translate3d(0%, 0, 0);
// 		     -o-transform: translate3d(0%, 0, 0);
// 		        transform: translate3d(0%, 0, 0);
// 	}

// 	&.left .modal-content,
// 	&.right .modal-content {
// 		height: 100%;
// 		overflow-y: auto;
// 	}
	
// 	// &.left .modal-body,
// 	// &.right .modal-body {
// 	// 	// padding: 15px 15px 80px;
// 	// 	// padding: 15px;
// 	// }

// /*Left*/
// 	&.left .modal-dialog {
// 		left: -$side-width;
// 		-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
// 		   -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
// 		     -o-transition: opacity 0.3s linear, left 0.3s ease-out;
// 		        transition: opacity 0.3s linear, left 0.3s ease-out;
// 	}
	
// 	&.left.in .modal-dialog {
// 		left: 0;
// 	}
        
// /*Right*/
// 	&.right .modal-dialog {
// 		right: -$side-width;
// 		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
// 		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
// 		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
// 		        transition: opacity 0.3s linear, right 0.3s ease-out;
// 	}
	
// 	&.right.in .modal-dialog {
// 		right: 0;
// 	}

	// /* ----- MODAL STYLE ----- */
	// .modal-content {
	// 	border-radius: 0;
	// 	border: none;
	// }

	// .modal-header {
	// 	border-bottom-color: #EEEEEE;
	// 	background-color: #FAFAFA;
	// }

	// /* ----- scrollable fix ----- */
	// .modal-dialog.modal-dialog-scrollable {
	// 	max-height: none !important;

	// 	.modal-content {
	// 		max-height: none !important;
	// 	}
	// }
}