$CDN_FS_IMG: "https://valetto.feedspot.com/img/";
// $reader-height: 5rem;
// $nav-scroller-height: 2.75rem;
// $side-width: 320px;

// $navbarHeight: 56px;
$navbarHeight: 2.5rem;
$announcementHeight: 44px;
$sidebarWidth: 260px;
$c2MaxWidth: 888px;

$rsTopHeight: 72px;
$rsBottomHeight: 50px;

$settingsHeight: 40px;
$podcastHeight: 40px;
$indpkgHeight: 40px;

$spotlightSearchHeight: 58px;
$spotlightTabHeight: 27px;

// theme
$themeGrey: #F8F8FA;
$themeGrey2: #686D72;
$themeGrey3: #e5e5e8;
$themeYellow: #F9D8304D;
$themeGold: #ddb422;
$themeSidebarBlack: #333;
$themeTitleBlack: #333;

// bs4
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$btn-focus-box-shadow: none;


$mail:#202020;
$copy-link:#2b8bff;
$dots:#202020;
$onenote:#7E1FB0;
$twitter:#1DA1F2;
$evernote:#00A82D;
$linkedin:#0077B5;
$pocket:#EF4056;
$facebook:#1877F2;
$buffer:#168EEA;
$wordpress:#21759B;
$email:#D44638;
$pintrest:#BD081C;
$instapaper:#1F1F1F;
$custom-sharing:#000000;
$google:#ea4335;
$blogger:#F57D00;
$reddit:#FF4500;
$tumblr:#35465C;
$mastodon:#6364FF;
$diigo:#4A8EFF;