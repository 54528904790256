// .nav-menu {
//     padding-right: 0.5rem;
//     color: #fff;
//     cursor: pointer;
// }

.navbar {
    background-color: $white;
    color: #333;
    height: $navbarHeight;
    padding: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    
    @extend .border-bottom;

    &.stick {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 4;
        // z-index: $zindex-modal-backdrop+1;
    }

    .navbar-brand, .nav-link, .btn-link {
        color: $white;
    }

    .fs-big {
        font-size: 1.2rem;
    }

    .left {
        padding: 0 10px;
        flex-shrink: 0;

        @include desktop {
            width: $sidebarWidth;
        }
    }

    .right {
        padding: 0 10px 0 0;
        display: flex;
        width: 100%;
        flex-grow: 1;
        justify-content: space-between;

        .rl {
            flex-grow: 1;
            width: 100%;
            padding-right: 10px;
            
            .input-group {
                width: 100%;
                max-width: 600px;
                // background-color: lighten($primary, 30%);
                background-color: $white;
                // border: 0;

                .input-group-prepend {
                    background-color: $themeGrey3;
                    // border: 0;
                    .input-group-text {
                        border-right: 0px;
                        // background-color: transparent;
                        // border: 0;
                    }
                }

                .form-control {
                    border-color: $border-color;
                    // border: 0;
                    // border-left: 0px;
                    // border-right: 0px;
                    background-color: transparent;
                }

                .input-group-append {
                    // border: 0;
                    .clear {
                        // color: $primary;
                        color: #333;
                        background-color: transparent;
                        border-color: #ced4da;
                        // border-left: 0px;
                    }

                    .btn {
                        // border: 0;
                        padding: 0.15rem 0.5rem;
                        
                    }
                }
            }
        }

        .rr {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            flex-shrink: 0;
            align-items: center;

            & > .item {
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
    }
}