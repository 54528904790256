@import "./bootstrap";
@import "./swiper";
// @import "~bootstrap/scss/bootstrap";

:root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #51505D;
    --toastify-color-info: #3498db;
    --toastify-color-success: #589F7B;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #DC5960;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);
}
@import "~react-toastify/scss/main";

@import "~react-dates/lib/css/_datepicker.css";
// @import "~rc-time-picker/assets/index.css";
// @import "~react-bootstrap-typeahead/css/Typeahead.css";
@import '~react-modal-video/scss/modal-video.scss'; 