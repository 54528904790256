$pageWidth: 750px;
$checkoutWidth: 500px;
// .paypal-buttons {
//     max-width: 100% !important;
// }
// .paypal-button-container {
//     max-width: 100% !important;
// }

.payments {
    color: $dark;

    .payments_notification_float {
        position: fixed;
        top: $navbarHeight;
        width: 100%;
    }

    .payments_notification {
        background-color: $themeYellow;
        padding: 10px;
        display: flex;
        justify-content: center;

        .edit_section {
            font-weight: 600;
            font-size: 90%;
            color: #686D72;

            > span {
                border-bottom: 1px dashed #686D72;

                &:not(:last-child) {
                    margin-right: 1rem;
                }
            }

            @include desktop {
                margin-left: 1rem;
            }
        }

        @include mobile() {
            flex-direction: column;
        }
    }

    .payments_checkout {
        padding: 1rem 0;

        .payments_checkout_split {
            max-width: $checkoutWidth;
            width: 100%;
            margin: 0.8rem auto;
            text-align: center;

        }

        .payments_checkout_item {
            max-width: $checkoutWidth;
            width: 100%;
            margin: 0 auto;

            &.payments_checkout--stripe {
                
            .card {
                background-color: white;
                padding: 10px 20px 11px;
                border-radius: 5px;
                width: 100%;
                border: 1px solid #afafaf;
                box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
            }

            .pay-button{
                padding: 0.7rem 2rem;
                width: 100%;
                margin: 1rem 0;
                color: white;
                font-weight: bold;
                font-size: medium;
                background-color: $primary;
                border: 0;
                border-radius: 5px;
                box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
                transition: box-shadow 500ms;
                cursor: pointer;
                    
                &:disabled{
                    background-color: #afafaf;
                    box-shadow: none;
                    cursor: default;
                }
                &:disabled:hover{
                    box-shadow: none;
                }
                &:hover{
                    box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
                }
            }
                
            }

            &.payments_checkout--paypal {

            }
        }

        
    }

    .payments_checkout_header {
        max-width: $pageWidth;
        width: 100%;
        margin: 0 auto;
        color: $themeGrey2;

        .payments_checkout_header--1 {
            padding: 30px 0 0 0;
            text-align: center;
            color: $dark;

            @include mobile {
                font-size: 80%;
            }

            .payments_start {
                font-size: 1.8rem;
                line-height: 2.2rem;

                @include mobile {
                    font-size: 1.2rem;
                }
            }
    
            .trial_dates_container {
                color: $themeGrey2;
    
                .trial_dates {
                    color: $dark;
                    font-weight: 500;
                }
            }

        }

        .form-check-label {
            // text-align: left;
            // min-width: 150px;
            line-height: 1.6rem;
        }

        .plan_name {
            font-size: 1.4rem;
            font-weight: 500;
        }

        .strike {
            text-decoration: line-through;
        }

        .total-container {
            width: 100%;
            max-width: $checkoutWidth;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding: 1rem;
            font-weight: 600;
            @extend .border;
            @extend .mt-4;
        }

        .checkout_savings {
            font-size: 80%;
            line-height: 0.8;

            .checkout_savings--amount {

            }

            .checkout_savings--dicount {

            }
        }

        .sym {
            color: $dark;
        }

        .amnt {
            color: $dark;
        }

        .plan_name {
            color: $dark;
        }
    }

    .payments_header {
        padding: 30px 0;
        text-align: center;
        background-color: $themeGrey;

        .payments_start {
            font-size: 1.8rem;
            line-height: 2.2rem;
        }

        .trial_dates_container {
            color: $themeGrey2;

            .trial_dates {
                color: $dark;
                font-weight: 500;
            }
        }

        .switch-container {
            display: flex;
            justify-content: center;
            align-items: center;

            .custom-switch {
                margin: -7px 15px 0 10px;
                width: 50px;
            }

            .save {
                color: $primary;
                white-space: nowrap;
                font-size: 80%;
            }
        }
    }

    .payments-table {
        max-width: $pageWidth;
        margin: 0px auto;
        color: $dark;
        @extend .table-borderless;

        tbody {

            & > tr {

                > td, th {
                    &:nth-child(n+2) {
                        text-align: center;
                    }
                }

                &:not(.payment-headers) {
                    > td, th {
                            &:nth-child(n+2) {
                                @extend .border-left;
                                
                                @include mobile {
                                    border-left: none !important;
                                }
                            }
                        }
                }
            }

        }

        .tooltiped {
            border-bottom: 1px dashed;
            cursor: pointer;
        }

        .plan-name {
            font-weight: 600;
        }

        .action-amount {
            position: relative;

            .amnt {
                margin-left: 10px;
                font-size: 2.5rem;
                font-weight: 400;
            }

            .sym {
                position: absolute;
                top: 1.4rem;
                font-weight: 400;
            }
        }

        .fs {

            &.fs-tick-round {
                color: $primary;
            }

            &.fs-close {
                @extend .text-muted;
            }
        }
    }

    .payments-mobile {
        max-width: $pageWidth;
        margin: 0 auto;
        width: 100%;

        & > .payments-plan-item {
            border-bottom: 1px solid $border-color;

            &:first-child {
                border-top: 1px solid $border-color;
            }

            .primary {
                position: relative;
                background-color: lighten($primary, 40%);
                padding: 15px 35px 15px 15px;
                cursor: pointer;

                &:before {
                    position: absolute;
                    right: 0;
                    content: "\e91c";
                    font-family: 'feedspot' !important;
                    speak: never;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    display: inline-block;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    top: 50%;
                    transform: translateY(-50%);
                    color: $primary;
                    right: 15px;
                }

                .plan {
                    // font-weight: 600;
                    font-weight: 700;
                    margin-right: 10px;
                }
            }

            .secondary {
                display: none;
                padding: 15px;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }

            &.open {

                .primary {
                    &:before {
                        content: "\e913";
                        color: $themeGrey2;
                    }
                }

                .secondary{
                    display: flex;;
                }
            }

            
        }
    }

    .faq-container {
        max-width: $pageWidth;
        margin: 0 auto;
        padding: 30px 0.75rem;

        @include mobile {
            padding: 30px 0;
        }

        .header {
            text-align: center;
            font-size: 2rem;
            line-height: 2.4rem;
            padding: 1rem;
            margin-bottom: 30px;
        }

        .faq-wrapper {
            
            & > .faq-item {
                padding: 20px 0;

                &:not(:last-child) {
                    @extend .border-bottom;
                }

                .title {
                    font-size: 1rem;
                    font-weight: 500;
                    position: relative;
                    padding-right: 1.2rem;
                    cursor: pointer;

                    @include desktop {
                        font-size: 1.2rem;
                    }

                    &:before {
                        position: absolute;
                        right: 0;
                        content: "\e91c";
                        font-family: 'feedspot' !important;
                        speak: never;
                        font-style: normal;
                        font-weight: normal;
                        font-variant: normal;
                        text-transform: none;
                        display: inline-block;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        top: 50%;
                        transform: translateY(-50%);
                        color: $primary;
                    }
                }

                .desc {
                    margin-top: 10px;
                    display: none;
                }

                &.open {

                    .title {
                        &:before {
                            content: "\e913";
                            color: $themeGrey2;
                        }
                    }

                    .desc{
                        display: block;
                    }
                }
            }
        }

    }

    .testimonial-container {
        max-width: $pageWidth;
        margin: 0 auto;
        padding: 30px 0.75rem;

        .header {
            text-align: center;
            font-size: 1.5rem;
            line-height: 2.4rem;
            padding: 1rem;
            margin-bottom: 30px;

            @include desktop {
                font-size: 2rem;
            }
        }

        .carousel {

            .carousel-inner {
                height: 250px;

                & > .carousel-item {
                    height: inherit;
                    padding: 30px 20px;

                    .avatar {
                        border-radius: 50%;
                        width: 50px;
                        margin-left: 10px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);

                        @include desktop {
                            width: 150px;
                        }
                    }
                    .carousel-caption {
                        color: #333;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: self-start;
                        text-align: left;
                        right: 20px;
                        left: 100px;
                        top: 50%;
                        transform: translateY(-50%);

                        @include desktop {
                            left: 200px;
                        }
                    }
                }
            }
            // .carousel-control-prev {
            //     color: #333 !important;
            // }
            // .carousel-control-prev {
            //     color: #333;
            // }
            .carousel-control-prev, .carousel-control-next {
                color: #333;
                width: 20px;
                font-size: 2rem;
            }
            .carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
                color: #333;

            }
        }
    }

    .features-container {
        max-width: $pageWidth;
        margin: 0 auto;
        padding: 30px 0.75rem;

        @include mobile {
            padding: 30px 0;
        }

        .header {
            text-align: center;
            font-size: 2rem;
            line-height: 2.4rem;
            padding: 1rem;
            margin-bottom: 30px;
        }

        .features-wrapper {
            display: grid;
            grid-gap: 40px;
            grid-template-columns: repeat(2, 1fr);

            @include desktop {
                grid-template-columns: repeat(6, 1fr);
            }

            & > .features-card {
                text-align: center;
                padding: 20px;
                border-radius: 10px;
                grid-column: span 2;
                @extend .shadow;

                &.features-card--1 {
                    @include desktop {
                        grid-column-start: 2;
                        grid-column-end: 4;
                    }
                }

                .title {
                    font-weight: 600;
                    margin-top: 20px;
                    margin-bottom: 4px;
                    font-size: 0.9rem;
                }

                .desc {
                    font-size: 0.8rem;
                }
            }
        }
    }

}