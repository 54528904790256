
$font-path: 'fonts/feedspot'; 

@font-face {
    font-family: 'feedspot';
    src:  url('#{$font-path}/feedspot.eot?f5txk6');
    src:  url('#{$font-path}/feedspot.eot?f5txk6#iefix') format('embedded-opentype'),
      url('#{$font-path}/feedspot.woff2?f5txk6') format('woff2'),
      url('#{$font-path}/feedspot.woff?f5txk6') format('woff'),
      url('#{$font-path}/feedspot.ttf?f5txk6') format('truetype'),
      url('#{$font-path}/feedspot.svg?f5txk6#feedspot') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  i.fs {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'feedspot' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    // // line-height: 1;
    // vertical-align: middle;
    display: inline-block;
  
    // /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } 

  .fs:before {
    color:inherit !important;
  }

  .fs-fw {
    width: 1.28571429em;
    text-align: center;
  }

  .fs-spin {
    -webkit-animation: fs-spin 2s infinite linear;
    animation: fs-spin 2s infinite linear;
  }
  .fs-pulse {
    -webkit-animation: fs-spin 1s infinite steps(8);
    animation: fs-spin 1s infinite steps(8);
  }
  @-webkit-keyframes fs-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes fs-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  .fs-rotate-90 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  // .fs-rotate-180 {
  //   -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  //   -webkit-transform: rotate(180deg);
  //   -ms-transform: rotate(180deg);
  //   transform: rotate(180deg);
  // }
  // .fs-rotate-180 {
  //   -webkit-transform: scale(-1);
  //   -ms-transform: scale(-1);
  //   transform: scale(-1);
  // }
  .fs-rotate-180 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
    -webkit-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  .fs-rotate-270 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .fs-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }
  .fs-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
  }

//  replace below
.fs-tiktok:before {
    content: "\ea39";
    color: #72727d;
  }
  .fs-briefcase:before {
    content: "\ea35";
    color: #72727d;
  }
  .fs-building:before {
    content: "\ea36";
    color: #72727d;
  }
  .fs-list-check:before {
    content: "\ea37";
    color: #72727d;
  }
  .fs-share-from-square:before {
    content: "\ea38";
    color: #72727d;
  }
  .fs-android:before {
    content: "\ea2b";
    color: #72727d;
  }
  .fs-check-light:before {
    content: "\ea2c";
    color: #72727d;
  }
  .fs-check:before {
    content: "\ea2d";
    color: #72727d;
  }
  .fs-clock-rotate-left:before {
    content: "\ea2e";
    color: #72727d;
  }
  .fs-flag:before {
    content: "\ea2f";
    color: #72727d;
  }
  .fs-lock-solid:before {
    content: "\ea30";
    color: #72727d;
  }
  .fs-magnifying-glass-light:before {
    content: "\ea31";
    color: #72727d;
  }
  .fs-paper-plane-light:before {
    content: "\ea32";
    color: #72727d;
  }
  .fs-pen-to-square1:before {
    content: "\ea33";
    color: #72727d;
  }
  // .fs-pencil:before {
  //   content: "\ea34";
  //   color: #72727d;
  // }
  .fs-badge-check-light:before {
    content: "\ea0e";
    color: #72727d;
  }
  .fs-bell-solid:before {
    content: "\ea0f";
    color: #72727d;
  }
  .fs-briefcase-solid:before {
    content: "\ea10";
    color: #72727d;
  }
  .fs-cart-shopping-solid:before {
    content: "\ea11";
    color: #72727d;
  }
  .fs-circle-down:before {
    content: "\ea12";
    color: #72727d;
  }
  .fs-circle-info-light:before {
    content: "\ea13";
    color: #72727d;
  }
  .fs-circle-play-solid:before {
    content: "\ea14";
    color: #72727d;
  }
  .fs-circle-right-solid:before {
    content: "\ea15";
    color: #72727d;
  }
  .fs-desktop:before {
    content: "\ea16";
    color: #72727d;
  }
  .fs-download-solid:before {
    content: "\ea17";
    color: #72727d;
  }
  .fs-file-check-solid:before {
    content: "\ea18";
    color: #72727d;
  }
  .fs-file-image-light:before {
    content: "\ea19";
    color: #72727d;
  }
  .fs-file-lines-light:before {
    content: "\ea1a";
    color: #72727d;
  }
  .fs-google-play:before {
    content: "\ea1b";
    color: #72727d;
  }
  .fs-google-podcast:before {
    content: "\ea1c";
    color: #72727d;
  }
  .fs-google2:before {
    content: "\ea1d";
    color: #72727d;
  }
  .fs-hourglass-start-solid:before {
    content: "\ea1e";
    color: #72727d;
  }
  .fs-image:before {
    content: "\ea1f";
    color: #72727d;
  }
  .fs-link-light:before {
    content: "\ea20";
    color: #72727d;
  }
  .fs-mobile-button-light:before {
    content: "\ea21";
    color: #72727d;
  }
  .fs-moon-stars:before {
    content: "\ea22";
    color: #72727d;
  }
  .fs-phone-light:before {
    content: "\ea23";
    color: #72727d;
  }
  .fs-phone-solid:before {
    content: "\ea24";
    color: #72727d;
  }
  .fs-podcast-light:before {
    content: "\ea25";
    color: #72727d;
  }
  .fs-right-to-bracket-solid:before {
    content: "\ea26";
    color: #72727d;
  }
  .fs-rocket-solid:before {
    content: "\ea27";
    color: #72727d;
  }
  .fs-star-light:before {
    content: "\ea28";
    color: #72727d;
  }
  .fs-stitcher:before {
    content: "\ea29";
    color: #72727d;
  }
  .fs-sun-bright:before {
    content: "\ea2a";
    color: #72727d;
  }
  .fs-mastodon:before {
    content: "\e975";
    color: #72727d;
  }
  .fs-pinterest-p:before {
    content: "\ea0a";
    color: #72727d;
  }
  .fs-quote-left:before {
    content: "\ea0b";
    color: #72727d;
  }
  .fs-quote-right:before {
    content: "\ea0c";
    color: #72727d;
  }
  .fs-book-open-reader-light:before {
    content: "\e9f2";
    color: #72727d;
  }
  .fs-bullseye-light:before {
    content: "\e9f3";
    color: #72727d;
  }
  .fs-clock-rotate-left-light:before {
    content: "\e9f4";
    color: #72727d;
  }
  .fs-envelope-light:before {
    content: "\e9f5";
    color: #72727d;
  }
  .fs-face-smile-light:before {
    content: "\e9f6";
    color: #72727d;
  }
  .fs-file-invoice-light:before {
    content: "\e9f7";
    color: #72727d;
  }
  .fs-gauge-max-light:before {
    content: "\e9f8";
    color: #72727d;
  }
  .fs-globe-light:before {
    content: "\e9f9";
    color: #72727d;
  }
  .fs-hand-point-up-light:before {
    content: "\e9fa";
    color: #72727d;
  }
  .fs-handshake-light:before {
    content: "\e9fb";
    color: #72727d;
  }
  .fs-money-bill-trend-up-light:before {
    content: "\e9fc";
    color: #72727d;
  }
  .fs-mug-hot-light:before {
    content: "\e9fd";
    color: #72727d;
  }
  .fs-people-group-light:before {
    content: "\e9fe";
    color: #72727d;
  }
  .fs-person-arrow-up-from-line-light:before {
    content: "\e9ff";
    color: #72727d;
  }
  .fs-puzzle-light:before {
    content: "\ea00";
    color: #72727d;
  }
  .fs-ranking-star-light:before {
    content: "\ea01";
    color: #72727d;
  }
  .fs-right-to-bracket-light:before {
    content: "\ea02";
    color: #72727d;
  }
  .fs-rss-light:before {
    content: "\ea03";
    color: #72727d;
  }
  .fs-signs-post-light:before {
    content: "\ea04";
    color: #72727d;
  }
  .fs-sliders-light:before {
    content: "\ea05";
    color: #72727d;
  }
  .fs-sparkles-light:before {
    content: "\ea06";
    color: #72727d;
  }
  .fs-trophy-star-light:before {
    content: "\ea07";
    color: #72727d;
  }
  .fs-umbrella-beach-light:before {
    content: "\ea08";
    color: #72727d;
  }
  .fs-user-light:before {
    content: "\ea09";
    color: #72727d;
  }
  .fs-align-center:before {
    content: "\e975";
    color: #72727d;
  }
  .fs-align-justify:before {
    content: "\e976";
    color: #72727d;
  }
  .fs-align-left:before {
    content: "\e977";
    color: #72727d;
  }
  .fs-align-right:before {
    content: "\e978";
    color: #72727d;
  }
  .fs-amazon-music:before {
    content: "\e979";
    color: #72727d;
  }
  .fs-angle-down:before {
    content: "\e97a";
    color: #72727d;
  }
  .fs-angle-left:before {
    content: "\e97b";
    color: #72727d;
  }
  .fs-angle-right:before {
    content: "\e97c";
    color: #72727d;
  }
  .fs-angle-up:before {
    content: "\e97d";
    color: #72727d;
  }
  .fs-apple-podcast:before {
    content: "\e97e";
    color: #72727d;
  }
  .fs-apple:before {
    content: "\e97f";
    color: #72727d;
  }
  .fs-arrow-down:before {
    content: "\e980";
    color: #72727d;
  }
  .fs-arrow-left:before {
    content: "\e981";
    color: #72727d;
  }
  .fs-arrow-right:before {
    content: "\e982";
    color: #72727d;
  }
  .fs-arrow-up-right-from-square:before {
    content: "\e983";
    color: #72727d;
  }
  .fs-arrow-up:before {
    content: "\e984";
    color: #72727d;
  }
  .fs-arrows-left-right:before {
    content: "\e985";
    color: #72727d;
  }
  // .fs-badge-check-solid:before {
  //   content: "\e986";
  //   color: #72727d;
  // }
  // .fs-bars:before {
  //   content: "\e987";
  //   color: #72727d;
  // }
  .fs-bell:before {
    content: "\e988";
    color: #72727d;
  }
  .fs-bookmark-solid:before {
    content: "\e989";
    color: #72727d;
  }
  .fs-bookmark:before {
    content: "\e98a";
    color: #72727d;
  }
  .fs-buffer2:before {
    content: "\e98b";
    color: #72727d;
  }
  .fs-calendar-days-light:before {
    content: "\e98c";
    color: #72727d;
  }
  .fs-calendar2:before {
    content: "\e98d";
    color: #72727d;
  }
  .fs-caret-down:before {
    content: "\e98e";
    color: #72727d;
  }
  .fs-caret-left:before {
    content: "\e98f";
    color: #72727d;
  }
  .fs-caret-right:before {
    content: "\e990";
    color: #72727d;
  }
  .fs-caret-up:before {
    content: "\e991";
    color: #72727d;
  }
  .fs-chart-mixed-light:before {
    content: "\e992";
    color: #72727d;
  }
  .fs-chevron-down:before {
    content: "\e993";
    color: #72727d;
  }
  .fs-chevron-left-light:before {
    content: "\e994";
    color: #72727d;
  }
  .fs-chevron-left:before {
    content: "\e995";
    color: #72727d;
  }
  .fs-chevron-right-light:before {
    content: "\e996";
    color: #72727d;
  }
  .fs-chevron-right:before {
    content: "\e997";
    color: #72727d;
  }
  .fs-chevron-up:before {
    content: "\e998";
    color: #72727d;
  }
  .fs-circle-dot:before {
    content: "\e999";
    color: #72727d;
  }
  .fs-circle-info:before {
    content: "\e99a";
    color: #72727d;
  }
  .fs-circle-minus:before {
    content: "\e99b";
    color: #72727d;
  }
  .fs-circle-play:before {
    content: "\e99c";
    color: #72727d;
  }
  .fs-circle-plus:before {
    content: "\e99d";
    color: #72727d;
  }
  .fs-circle-question:before {
    content: "\e99e";
    color: #72727d;
  }
  .fs-circle-up:before {
    content: "\e99f";
    color: #72727d;
  }
  .fs-circle:before {
    content: "\e9a0";
    color: #72727d;
  }
  .fs-clock-three:before {
    content: "\e9a1";
    color: #72727d;
  }
  .fs-clone:before {
    content: "\e9a2";
    color: #72727d;
  }
  .fs-code-light:before {
    content: "\e9a3";
    color: #72727d;
  }
  .fs-code2:before {
    content: "\e9a4";
    color: #72727d;
  }
  .fs-copy:before {
    content: "\e976";
    color: #72727d;
  }
  .fs-credit-card:before {
    content: "\e9a6";
    color: #72727d;
  }
  .fs-domain-authority:before {
    content: "\e9a7";
    color: #72727d;
  }
  .fs-download2:before {
    content: "\e9a8";
    color: #72727d;
  }
  .fs-ellipsis-stroke-vertical:before {
    content: "\e9a9";
    color: #72727d;
  }
  .fs-ellipsis-stroke:before {
    content: "\e9aa";
    color: #72727d;
  }
  .fs-envelope:before {
    content: "\e9ab";
    color: #72727d;
  }
  .fs-evernote2:before {
    content: "\e9ac";
    color: #72727d;
  }
  .fs-facebook-f:before {
    content: "\e9ad";
    color: #72727d;
  }
  .fs-feedspot:before {
    content: "\e9ae";
    color: #72727d;
  }
  .fs-file-excel:before {
    content: "\e9af";
    color: #72727d;
  }
  .fs-file-image:before {
    content: "\e9b0";
    color: #72727d;
  }
  .fs-file-invoice:before {
    content: "\e9b1";
    color: #72727d;
  }
  .fs-file-lines:before {
    content: "\e9b2";
    color: #72727d;
  }
  .fs-files:before {
    content: "\e9b3";
    color: #72727d;
  }
  .fs-filter-light:before {
    content: "\e9b4";
    color: #72727d;
  }
  .fs-floppy-disk:before {
    content: "\e9b5";
    color: #72727d;
  }
  .fs-folder-grid:before {
    content: "\e9b6";
    color: #72727d;
  }
  .fs-gear:before {
    content: "\e9b7";
    color: #72727d;
  }
  .fs-globe2:before {
    content: "\e9b8";
    color: #72727d;
  }
  .fs-grid-2-light:before {
    content: "\e9b9";
    color: #72727d;
  }
  .fs-grid-2-plus-light:before {
    content: "\e9ba";
    color: #72727d;
  }
  .fs-grid-2:before {
    content: "\e9bb";
    color: #72727d;
  }
  .fs-grid-round:before {
    content: "\e9bc";
    color: #72727d;
  }
  .fs-grid:before {
    content: "\e9bd";
    color: #72727d;
  }
  .fs-hand-holding-box-light:before {
    content: "\e9be";
    color: #72727d;
  }
  .fs-handshake:before {
    content: "\e9bf";
    color: #72727d;
  }
  .fs-headset:before {
    content: "\e9c0";
    color: #72727d;
  }
  .fs-hourglass:before {
    content: "\e9c1";
    color: #72727d;
  }
  .fs-house:before {
    content: "\e9c2";
    color: #72727d;
  }
  .fs-info:before {
    content: "\e9c3";
    color: #72727d;
  }
  .fs-instagram:before {
    content: "\e9c4";
    color: #72727d;
  }
  .fs-lightbulb-light:before {
    content: "\e9c5";
    color: #72727d;
  }
  .fs-lightbulb:before {
    content: "\e9c6";
    color: #72727d;
  }
  .fs-link2:before {
    content: "\e9c7";
    color: #72727d;
  }
  .fs-linkedin-in:before {
    content: "\e9c8";
    color: #72727d;
  }
  .fs-list2:before {
    content: "\e9c9";
    color: #72727d;
  }
  .fs-location-dot:before {
    content: "\e9ca";
    color: #72727d;
  }
  .fs-magnifying-glass-chart-light:before {
    content: "\e9cb";
    color: #72727d;
  }
  .fs-magnifying-glass-plus-light:before {
    content: "\e9cc";
    color: #72727d;
  }
  .fs-magnifying-glass:before {
    content: "\e9cd";
    color: #72727d;
  }
  .fs-mcd:before {
    content: "\e9ce";
    color: #72727d;
  }
  .fs-media-monitoring:before {
    content: "\e9cf";
    color: #72727d;
  }
  .fs-memo-pad:before {
    content: "\e9d0";
    color: #72727d;
  }
  .fs-messages:before {
    content: "\e9d1";
    color: #72727d;
  }
  .fs-mobile-retro:before {
    content: "\e9d2";
    color: #72727d;
  }
  .fs-pen-to-square:before {
    content: "\e9d3";
    color: #72727d;
  }
  .fs-phone:before {
    content: "\e9d4";
    color: #72727d;
  }
  .fs-pinterest:before {
    content: "\e9d5";
    color: #72727d;
  }
  .fs-play-solid2:before {
    content: "\e9d6";
    color: #72727d;
  }
  .fs-plus2:before {
    content: "\e9d7";
    color: #72727d;
  }
  .fs-podcast2:before {
    content: "\e9d8";
    color: #72727d;
  }
  .fs-pr:before {
    content: "\e9d9";
    color: #72727d;
  }
  .fs-publisher:before {
    content: "\e9da";
    color: #72727d;
  }
  .fs-reader:before {
    content: "\e9db";
    color: #72727d;
  }
  .fs-rss-combiner:before {
    content: "\e9dc";
    color: #72727d;
  }
  .fs-rss-widget:before {
    content: "\e9dd";
    color: #72727d;
  }
  .fs-rss2:before {
    content: "\e9de";
    color: #72727d;
  }
  .fs-scheduler:before {
    content: "\e988";
    color: #72727d;
  }
  .fs-sliders:before {
    content: "\e9e0";
    color: #72727d;
  }
  .fs-spotify:before {
    content: "\e9e1";
    color: #72727d;
  }
  .fs-square-check:before {
    content: "\e9e2";
    color: #72727d;
  }
  .fs-square-minus:before {
    content: "\e9e3";
    color: #72727d;
  }
  .fs-square:before {
    content: "\e9e4";
    color: #72727d;
  }
  .fs-star:before {
    content: "\e9e5";
    color: #72727d;
  }
  .fs-toggle-off:before {
    content: "\e986";
    color: #72727d;
  }
  .fs-toggle-on:before {
    content: "\e987";
    color: #72727d;
  }
  .fs-trash:before {
    content: "\e9e8";
    color: #72727d;
  }
  .fs-tunein:before {
    content: "\e9e9";
    color: #72727d;
  }
  .fs-users-light:before {
    content: "\e9ea";
    color: #72727d;
  }
  .fs-users:before {
    content: "\e9eb";
    color: #72727d;
  }
  .fs-video2:before {
    content: "\e9ec";
    color: #72727d;
  }
  .fs-volume-high:before {
    content: "\e9ed";
    color: #72727d;
  }
  .fs-wordpress2:before {
    content: "\e9ee";
    color: #72727d;
  }
  .fs-x-twitter:before {
    content: "\e9ef";
    color: #72727d;
  }
  .fs-xmark:before {
    content: "\e9f0";
    color: #72727d;
  }
  .fs-youtube2:before {
    content: "\e9f1";
    color: #72727d;
  }
  .fs-notification-solid:before {
    content: "\e974";
    color: #72727d;
    color: #72727d;
  }
  .fs-apps:before {
    content: "\e900";
    color: #72727d;
  }
  .fs-arrow:before {
    content: "\e901";
    color: #72727d;
  }
  .fs-articles:before {
    content: "\e902";
    color: #72727d;
  }
  .fs-blogger:before {
    content: "\e903";
    color: #72727d;
  }
  .fs-buffer:before {
    content: "\e904";
    color: #72727d;
  }
  .fs-calendar:before {
    content: "\e905";
    color: #72727d;
  }
  .fs-checkbox:before {
    content: "\e906";
    color: #72727d;
  }
  .fs-checkbox-blank:before {
    content: "\e907";
    color: #72727d;
  }
  .fs-checkbox-few:before {
    content: "\e908";
    color: #72727d;
  }
  .fs-chevron-arrow:before {
    content: "\e909";
    color: #72727d;
  }
  .fs-clock:before {
    content: "\e90a";
    color: #72727d;
  }
  .fs-clock1:before {
    content: "\e90b";
    color: #72727d;
  }
  .fs-clock2:before {
    content: "\e90c";
    color: #72727d;
  }
  .fs-clock3:before {
    content: "\e90d";
    color: #72727d;
  }
  .fs-clock4:before {
    content: "\e90e";
    color: #72727d;
  }
  .fs-clock5:before {
    content: "\e90f";
    color: #72727d;
  }
  .fs-clock6:before {
    content: "\e910";
    color: #72727d;
  }
  .fs-close:before {
    content: "\e911";
    color: #72727d;
  }
  .fs-code:before {
    content: "\e912";
    color: #72727d;
  }
  .fs-collapse:before {
    content: "\e913";
    color: #72727d;
  }
  .fs-custom-sharing:before {
    content: "\e914";
    color: #72727d;
  }
  .fs-delete:before {
    content: "\e915";
    color: #72727d;
  }
  .fs-diigo:before {
    content: "\e916";
    color: #72727d;
  }
  .fs-document:before {
    content: "\e917";
    color: #72727d;
  }
  .fs-dots:before {
    content: "\e918";
    color: #72727d;
  }
  .fs-dropbox:before {
    content: "\e919";
    color: #72727d;
  }
  .fs-dropbox-solid:before {
    content: "\e91a";
    color: #72727d;
  }
  .fs-evernote:before {
    content: "\e91b";
    color: #72727d;
  }
  .fs-expand:before {
    content: "\e91c";
    color: #72727d;
  }
  .fs-expand-arrow:before {
    content: "\e91d";
    color: #72727d;
  }
  .fs-export:before {
    content: "\e91e";
    color: #72727d;
  }
  .fs-facebook:before {
    content: "\e91f";
    color: #72727d;
  }
  .fs-favourite:before {
    content: "\e920";
    color: #72727d;
  }
  .fs-favourite-in:before {
    content: "\e921";
    color: #72727d;
  }
  .fs-folder-close:before {
    content: "\e922";
    color: #72727d;
  }
  .fs-folder-open:before {
    content: "\e923";
    color: #72727d;
  }
  .fs-globe:before {
    content: "\e924";
    color: #72727d;
  }
  .fs-google:before {
    content: "\e925";
    color: #72727d;
  }
  .fs-home:before {
    content: "\e926";
    color: #72727d;
  }
  .fs-hootsuite:before {
    content: "\e927";
    color: #72727d;
  }
  .fs-import:before {
    content: "\e928";
    color: #72727d;
  }
  .fs-information:before {
    content: "\e929";
    color: #72727d;
  }
  .fs-instapaper:before {
    content: "\e92a";
    color: #72727d;
  }
  .fs-link:before {
    content: "\e92b";
    color: #72727d;
  }
  .fs-linkedin:before {
    content: "\e92c";
    color: #72727d;
  }
  .fs-list:before {
    content: "\e92d";
    color: #72727d;
  }
  .fs-loader:before {
    content: "\e92e";
    color: #72727d;
  }
  .fs-lock:before {
    content: "\e92f";
    color: #72727d;
  }
  .fs-mail:before {
    content: "\e930";
    color: #72727d;
  }
  .fs-menu:before {
    content: "\e931";
    color: #72727d;
  }
  .fs-minus:before {
    content: "\e932";
    color: #72727d;
  }
  .fs-monitor:before {
    content: "\e933";
    color: #72727d;
  }
  .fs-move-folder:before {
    content: "\e934";
    color: #72727d;
  }
  .fs-notification:before {
    content: "\e935";
    color: #72727d;
  }
  .fs-onenote:before {
    content: "\e936";
    color: #72727d;
  }
  .fs-open-external:before {
    content: "\e937";
    color: #72727d;
  }
  .fs-organize:before {
    content: "\e938";
    color: #72727d;
  }
  .fs-pdf:before {
    content: "\e939";
    color: #72727d;
  }
  .fs-pinboard:before {
    content: "\e93a";
    color: #72727d;
  }
  .fs-pinboard-in:before {
    content: "\e93b";
    color: #72727d;
  }
  .fs-pintrest:before {
    content: "\e93c";
    color: #72727d;
  }
  .fs-play:before {
    content: "\e93d";
    color: #72727d;
  }
  .fs-plus:before {
    content: "\e93e";
    color: #72727d;
  }
  .fs-pocket:before {
    content: "\e93f";
    color: #72727d;
  }
  .fs-podcast:before {
    content: "\e940";
    color: #72727d;
  }
  .fs-print:before {
    content: "\e941";
    color: #72727d;
  }
  .fs-profile:before {
    content: "\e942";
    color: #72727d;
  }
  .fs-publish:before {
    content: "\e943";
    color: #72727d;
  }
  .fs-question:before {
    content: "\e944";
    color: #72727d;
  }
  .fs-radio-checked:before {
    content: "\e945";
    color: #72727d;
  }
  .fs-radio-unchecked:before {
    content: "\e946";
    color: #72727d;
  }
  .fs-reddit:before {
    content: "\e947";
    color: #72727d;
  }
  .fs-refresh:before {
    content: "\e948";
    color: #72727d;
  }
  .fs-rename:before {
    content: "\e949";
    color: #72727d;
  }
  .fs-renren:before {
    content: "\e94a";
    color: #72727d;
  }
  .fs-rss:before {
    content: "\e94b";
    color: #72727d;
  }
  .fs-rss-logo:before {
    content: "\e94c";
    color: #72727d;
  }
  .fs-search:before {
    content: "\e94d";
    color: #72727d;
  }
  .fs-setting:before {
    content: "\e94e";
    color: #72727d;
  }
  .fs-share:before {
    content: "\e94f";
    color: #72727d;
  }
  .fs-sub-pinboard:before {
    content: "\e950";
    color: #72727d;
  }
  .fs-switcher:before {
    content: "\e951";
    color: #72727d;
  }
  .fs-team:before {
    content: "\e952";
    color: #72727d;
  }
  .fs-tick:before {
    content: "\e953";
    color: #72727d;
  }
  .fs-tick-round:before {
    content: "\e954";
    color: #72727d;
  }
  .fs-tumblr:before {
    content: "\e955";
    color: #72727d;
  }
  .fs-twitter:before {
    content: "\e956";
    color: #72727d;
  }
  .fs-view:before {
    content: "\e957";
    color: #72727d;
  }
  .fs-wordpress:before {
    content: "\e958";
    color: #72727d;
  }
  .fs-youtube:before {
    content: "\e959";
    color: #72727d;
  }
  .fs-tour:before {
    content: "\e95a";
    color: #72727d;
  }
  .fs-keyboard:before {
    content: "\e95b";
    color: #72727d;
  }
  .fs-reset-password:before {
    content: "\e95c";
    color: #72727d;
  }
  .fs-video:before {
    content: "\e95d";
    color: #72727d;
  }
  .fs-logout:before {
    content: "\e95e";
    color: #72727d;
  }
  .fs-sub-pinboard-in:before {
    content: "\e95f";
    color: #72727d;
  }
  .fs-add-to-playlist:before {
    content: "\e960";
    color: #72727d;
  }
  .fs-backward:before {
    content: "\e961";
    color: #72727d;
  }
  .fs-download:before {
    content: "\e962";
    color: #72727d;
  }
  .fs-forward:before {
    content: "\e963";
    color: #72727d;
  }
  .fs-full-volume:before {
    content: "\e964";
    color: #72727d;
  }
  .fs-mid-volume:before {
    content: "\e965";
    color: #72727d;
  }
  .fs-mute-volume:before {
    content: "\e966";
    color: #72727d;
  }
  .fs-next:before {
    content: "\e967";
    color: #72727d;
  }
  .fs-pause:before {
    content: "\e968";
    color: #72727d;
  }
  .fs-pause-round:before {
    content: "\e969";
    color: #72727d;
  }
  .fs-playlist:before {
    content: "\e96a";
    color: #72727d;
  }
  .fs-playlist-added:before {
    content: "\e96b";
    color: #72727d;
  }
  .fs-play-round:before {
    content: "\e96c";
    color: #72727d;
  }
  .fs-play-solid:before {
    content: "\e96d";
    color: #72727d;
  }
  .fs-previous:before {
    content: "\e96e";
    color: #72727d;
  }
  .fs-repeat:before {
    content: "\e96f";
    color: #72727d;
  }
  .fs-repeat-all:before {
    content: "\e970";
    color: #72727d;
  }
  .fs-shuffle:before {
    content: "\e971";
    color: #72727d;
  }
  .fs-sort:before {
    content: "\e972";
    color: #72727d;
  }
  .fs-share-default:before {
    content: "\e973";
    color: #72727d;
  }
  .fs-font-case:before {
    content: "\e981";
    color: #72727d;
  }

 .fs-lock-solid:before {
    content: "\e982";
    color: #72727d;
  }
  .fs-palette:before {
    content: "\e983";
    color: #72727d;
  } 
  .fs-pencil:before{
    content: "\e984";
    color: #72727d;
  }
  .fs-book-open:before{
    content:"\e989";
    color: #72727d;
  }
  .fs-newspaper:before{
    content:"\e98a";
    color: #72727d;
  }
  .fs-highlighter-line:before{
    content:"\e98b";
    color: #72727d;
  }