.view_container {
  .entries__container.thumb {
    // margin: 0 -15px;

    > .entries_item {
      // border-left: 2px solid transparent;
      border: 1px solid transparent;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &:hover {
        border: 1px solid darken($border-color, 10%);
      }

      &.entries_item--close {
        display: flex;
        padding: 10px 20px;
        cursor: pointer;
        user-select: none;
        // @extend .border-bottom;

        // &:first-child {
        //     @extend .border-top;
        // }

        &:hover {
          background-color: #f5f5f5;

          .entries_item {
            &__main {
              &__primary {
                .share {
                  display: flex;
                  flex-shrink: 0;
                  align-items: center;
                  // margin-right: 5px;
                  // padding: 10px;
                  // border: 1px solid $border-color;
                  // font-size: 1.2rem;
                  // margin-bottom: 20px;

                  .item {
                    // width: 30px;
                    // height: 12px;
                    // line-height: 12px;
                    padding: 0.05rem;
                    margin-right: 0.15rem;
                    display: inline-block;
                    text-align: center;
                    color: $text-muted;

                    &:hover {
                      // color: #212529;
                      // background-color: #d1d1d6;
                    }
                  }

                  .item--scheduler {
                    border: 1px solid $themeGrey2;
                    font-size: 10px;
                    line-height: 12px;
                    padding: 2px 5px;
                    margin-left: 5px;
                    border-radius: 2px;

                    &:hover {
                      color: #212529;
                      background-color: $themeGrey3;
                      // background-color: #d1d1d6;
                    }
                  }
                }
              }
            }
          }
        }

        .entries_item {
          &__thumb {
            flex-shrink: 0;
            width: 140px;
            height: 80px;
            object-fit: cover;
            margin-right: 20px;
            background-color: #e0e0e0;

            @include mobile {
              width: 60px;
              height: 60px;
              object-fit: cover;
            }
          }

          &__main {
            flex-grow: 1;
            min-width: 0;

            &__primary {
              display: flex;
              padding-bottom: 2px;
              font-size: 1.15rem;
              color: #51505d;

              @include mobile {
                font-size: 0.85rem;
              }

              .title {
                font-size: 1rem;
                flex-grow: 1;
                font-weight: 600;
                color: $themeTitleBlack;
                // @extend .text-truncate;
              }
              .favorite {
                flex-shrink: 0;
                padding-left: 0.5rem;
                @extend .text-muted;
              }
              .share {
                display: none;
                @extend .text-dark;

                & > .item {
                  // &:not(:first-child) {
                  //     padding-left: 0.5rem;
                  // }
                }
              }
            }

            &__secondary {
              display: flex;
              padding-bottom: 4px;
              font-size: 0.85rem;

              .details {
                display: flex;
                min-width: 0;
                width: 100%;

                .feed {
                  padding-right: 5px;
                  @extend .text-truncate;
                  @extend .text-muted;
                }
                .writer {
                  padding-right: 5px;
                  @extend .text-truncate;
                  @extend .text-muted;
                }
                .time {
                  flex-shrink: 0;
                  @extend .text-muted;
                }
              }
            }

            &__desc {
              // @extend .text-truncate;
              @extend .text-dark;
              font-size: 0.85rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;

              @include mobile {
                display: none;
              }
            }

            &__podcast {
              @include desktop {
                margin-top: 5px;
              }
            }

            &__video {
              @include desktop {
                margin-top: 5px;
              }
            }
          }
        }

        &.entries_item--read {
          background-color: $white;
          // background-color: #FAFAFA;
          @extend .text-muted;

          .entries_item {
            &__thumb {
              // filter: grayscale(100%);
            }

            &__main {
              &__primary {
                .title {
                  font-weight: normal;
                }
              }
            }
          }
        }
      }

      &.entries_item--open {
        padding: 5px 10px;
        // border-left: 2px solid transparent;
        border: 1px solid transparent;
        @extend .border-bottom;

        .entries_item {
          &__close {
            float: right;
            position: sticky;
            // top: $reader-height;
            top: 0;
            margin-top: -5px;
            margin-right: -10px;
            background-color: #9e9e9e;
            border-bottom-left-radius: 100%;
            padding: 0px 4px 8px 10px;
            color: white;
            transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
            cursor: pointer;

            &:hover {
              padding: 1px 4px 16px 20px;
              background-color: #757575;
            }
          }
          &__feed {
            font-size: 1.2rem;
            font-weight: bold;
            @extend .text-primary;
          }
          &__secondary {
            display: flex;
            @extend .text-muted;

            a {
              @extend .text-muted;
            }

            .author {
              padding-right: 0.5rem;
            }
            .writer {
              padding-right: 0.5rem;
            }
            // .time {

            // }
          }
          &__share {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;

            .item {
              cursor: pointer;
              margin: 5px 5px 5px 0;
            }
            .dropdown-toggle.dropdown-share {
              padding: 0;
              @extend .text-muted;

              &::after {
                display: none;
              }
            }
            .btn {
              padding: 0 0.25rem;
            }
          }
          &__img {
            height: auto;
            width: auto;
            max-height: 200px;
            max-width: 100%;
            object-fit: cover;
          }
          &__content {
            margin-top: 0.75rem;

            img {
              display: flex;
              height: auto;
              width: auto;
              max-height: 200px;
              max-width: 100%;
              object-fit: contain;
            }
            // p {
            //     margin: 0;
            // }
          }
          &__visit {
            text-align: center;
            margin: 1rem 0;
          }
          &__footer {
            display: flex;
            align-items: center;

            .item {
              display: flex;
              align-items: center;
              padding: 0.25rem;
              margin-right: 0.5rem;
              cursor: pointer;
              @extend .text-dark;

              a {
                text-decoration: none;
                @extend .text-dark;
              }

              &__text {
                padding-left: 0.25rem;
              }
            }
          }
        }
      }

      &.entries_item--active {
        // border-left: 2px solid theme-color("primary");
        border: 1px solid darken($border-color, 10%);
      }

      &.entries_item--read {
        background-color: #fafafa;
        @extend .text-muted;

        .entries_item__feed {
          font-weight: normal !important;
        }
      }
    }
  }
}
