.scheduler {

    .modal-header {

        .modal-title {

            font-size: 1rem;
        }

    }

    .modal-body {

        .media {
            margin: 0 auto;
            height: 200px;
            min-width: 200px;
            object-fit: cover;
        }

        .timepicker-btn {
            @extend .btn-link;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            height: 34px;
            line-height: 34px;
            padding: 0px 0.5rem;
            border-color: #dbdbdb;
            background-color: #fff;
            border-left: none;
            color: #5d5d5d;
            cursor: text;
            box-shadow: none;

            &:hover, &:focus, &:active {
                outline: none;
                color:inherit;
                text-decoration: none;
                box-shadow: none;
                border-bottom: 2px solid #008489;
            }
        }
    }

    &-container {
        @include mobile()
        {
            padding: 10px;
        }

        &__accounts {

            &__item {
                width: 50px;

                .top {
                    position: relative;

                    img {
                        width: 50px;
                        height: 50px;
                        object-fit: contain;
                        @extend .border;
                    }

                    // svg {
                    i.fs {
                        position: absolute;
                        background-color: #fff;
                        bottom: 0;
                        left: 0;
                    }

                }
                .bottom {
                    font-size: 0.75rem;
                    @extend .text-truncate;
                }
            }

        }

        &__picker {
            display: flex;
            justify-content: center;
            align-items: center;

            // .playground {
            //     height: 500px;
            // }
        }

    }
}