.modal {
    .modal-header {
        justify-content: flex-start;

        .closeModal {
            cursor: pointer;
            // background: #95959D;
            // border-radius: 50%;
            // color: #fff !important;
            // font-size: 13px;
            // width: 20px;
            // height: 20px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            margin-left: auto;

            // cursor: pointer;
            // background: #95959D;
            // border-radius: 50%;
            // color: #fff;
            // width: 16px;
            // height: 16px;
            // // font-size: 20px;
            // font-size: 1.4rem;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // margin-left: auto;
            // flex-shrink: 0;
            // line-height: normal;

            // &:before {
            //     // font-size: 80%;
            //     transform: scale(0.8);
            // }

            // &:before {
                // content: '\00D7' !important;
                // content: "✖" !important;
                // color: #fff !important;
                // color: #72727d;
                // transform: scale(0.6);
            // }
        }

        .btn-right {
            margin-left: auto;
            
            + .close {
                margin: -1rem -1rem -1rem 0rem;
            }
        }
    }

    //scrollabe modal
    .modal-dialog-scrollable {
        .modal-body {
            @include desktop {
                max-height: 50vh;
                max-height: 50dvh;
            }
        }
    }
}

