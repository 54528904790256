$thumbsize: 120px;
$thumbsizeHeight: 160px;

#root .announcement + .container-fluid {
    .main.indpkg {
        .main_container {
            background-color: $themeGrey3;
            height: calc(100vh - #{$navbarHeight} - #{$indpkgHeight} - #{$announcementHeight}) !important;
            height: calc(100dvh - #{$navbarHeight} - #{$indpkgHeight} - #{$announcementHeight}) !important;
        }
    }
}

.main.indpkg {
    // background-color: $themeGrey;
    background-color: $themeGrey3;

    &.contained {

        .main-indpkg {
            max-width: $c2MaxWidth;
            margin: 0 auto;
            border-left: 1px solid $border-color;
            border-right: 1px solid $border-color;
        }
        
        .main_container {

            .main_container-outer {
                max-width: $c2MaxWidth;
                margin: 0 auto;
                border-left: 1px solid $border-color;
                border-right: 1px solid $border-color;
            }
        }
    }

    .main-indpkg {
        position: sticky;
        top: 0;
        background-color: $white;
        display: flex;
        flex-direction: column;
        z-index: 1;
    
        .main-indpkg__top {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
            height: $indpkgHeight;
            @extend .border-bottom;

            .primary {
                font-size: 1.2rem;
                font-weight: 600;
                display: flex;
                width: 100%;

                & > .bc-link {
                    // display: inline-flex;
                    display: flex;
                    align-items: center;

                    // > .fs {
                    //     &:before {
                    //     vertical-align: text-bottom;
                    //     }
                    // }

                    &.tt {
                        min-width: 0;

                        .tt-text {
                            @extend .text-truncate
                        }
                    }

                    .hide-mob {
                        @include mobile {
                            display: none;
                        }
                    }

                    .hide-desk {
                        @include desktop {
                            display: none;
                        }
                    }

                    &:not(.active) {
                        color: $primary;
                        cursor: pointer;
                    }

                    &:not(:last-child) {

                        &:after {
                            font-family: 'feedspot' !important;
                            speak: never;
                            font-style: normal;
                            font-weight: normal;
                            font-variant: normal;
                            text-transform: none;
                            line-height: 1;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            // content: "\e901";
                            content: "\e909";
                            padding-left: 5px;
                            padding-right: 5px;
                            font-size: 80%;
                            vertical-align: middle;
                            color: $themeGrey2;
                            padding-top: 2px;
                        }
                    }
                }
            }
        }
    }

    .main_container {
        background-color: $themeGrey3;
        height: calc(100vh - #{$navbarHeight} - #{$indpkgHeight});
        height: calc(100dvh - #{$navbarHeight} - #{$indpkgHeight});
        overflow-y: auto;
        overflow-y: overlay;
        // scrollbar-gutter: stable both-edges;
        position: relative;

        @extend .noselect;

        .main_container-outer {

            background-color: $white;
            min-height: 100%;

            .indpkg-heading-wrapper {
                padding: 10px 20px;

                .indpkg-heading{
                    font-size: 1.2rem;
                    font-weight: 600;
                }

                .indpkg-secondary {
                    margin-top: 5px;
                }

            }
        }

        .indpkg-item-container {

            .indpkg_container {
                // padding: 20px;
                
                // background-color: $themeGrey3;
                // max-width: $c2MaxWidth;
                // margin: 0 auto;
                // border-left: 1px solid $border-color;
                // border-right: 1px solid $border-color;

                .indpkg__sub-heading {
                    padding: 10px 20px;
                    font-weight: 600;
                    font-size: 1rem;
                    text-transform: uppercase;
                    margin-top: 20px;

                    &.indpkg__sub-heading--small {
                        font-size: 0.7rem;
                        margin-top: 0px;
                    }
                }

                .indpkg__list-wrapper {
                    width: 100%;
                    display: -ms-grid;
                    display: grid;
                    grid-gap: 20px;
                    -ms-grid-columns: 1fr;
                    grid-template-columns: 1fr;
                    padding: 10px 20px;

                    @include desktop {
                        -ms-grid-columns: 1fr 1fr 1fr 1fr;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                    }

                    & > .indpkg__list-item {
                        display: flex;
                        justify-content: space-between;
                        border: 1px solid $border-color;
                        padding: 10px 10px;
                        border-radius: 4px;
                        align-items: center;

                        .indpkg__list-item--name {

                        }

                        .indpkg__list-item--btn {
                            flex-shrink: 0;
                        }
                    }
                }

                &--search {
                    display: flex;
                    align-items: center;
                    height: $spotlightSearchHeight;
                    padding: 10px 20px;

                    background-color: $white;

                    .input-group {
                        max-width: 600px;
                        background-color: lighten($primary, 40%);

                        .input-group-prepend {
                            .input-group-text {
                                border-right: 0px;
                                background-color: transparent;
                                border-color: transparent;
                            }

                        }
                        .form-control {
                            border-left: 0px;
                            border-right: 0px;
                            background-color: transparent;
                            border-color: transparent;

                            &:focus {
                                outline: none;
                                box-shadow: none;
                            }
                        }
                        .input-group-append {
                            .clear {
                                color: $primary;
                                background-color: transparent;
                                border-color: transparent;
                                // border-color: #ced4da;
                                border-left: 0px;
                            }
                        }
                    }

                    .autocomplete {
                        position: absolute;
                        top: 100%;
                        background-color: $white;
                        left: 0;
                        right: 0;
                        box-shadow: 0px 10px 20px #0000001a;
                        z-index: 2;
                        max-height: 70vh;
                        max-height: 70dvh;
                        overflow-y: auto;
                        overflow-y: overlay;
                        // scrollbar-gutter: stable both-edges;

                        .autocomplete--header {
                            font-size: 0.8rem;
                            display: flex;
                            justify-content: space-between;
                            padding: 4px 10px;
                            // border-bottom: 1px solid $border-color;
                            
                            .title {
                                text-transform: uppercase;
                            }

                            .action {
                                text-transform: uppercase;
                                color: $primary;
                                cursor: pointer;
                            }
                        }

                        &.default {

                            & > .item {
                                
                                &:hover, &.active {
                                    background-color: rgba(0, 0, 0, 0.1);
                                }

                                .label {
                                    background: lighten($primary, 30%);;
                                    margin-right: 10px;
                                    padding: 0.2em 0.4em;
                                    border-radius: 4px;
                                    font-weight: 600;
                                }

                                .desc {

                                }
                            }

                        }

                        & > .item {
                            padding: 10px;
                            font-size: 0.8rem;
                            cursor: pointer;

                            &:hover {
                                background-color: rgba(0, 0, 0, 0.1);
                            }
                        }

                        .autocomplete--content {

                            & > .feed-item {
                                display: flex;
                                font-size: 0.8rem;
                                cursor: pointer;
                                align-items: center;

                                &:not(:last-child) {
                                    border-bottom: 1px solid $border-color;
                                }

                                &:hover, &.active {
                                    background-color: rgba(0, 0, 0, 0.1);
                                }

                                
                                .feed-item__img {
                                    margin: 10px;
                                }

                                .feed-item__info {
                                    margin: 10px;

                                    .feed-item__info__name {
                                        font-weight: 600;
                                    }

                                    .feed-item__info__dmn {
                                    }

                                    .feed-item__info__desc {
                                        display: none;
                                    }
                                }

                                .feed-item__followers {
                                    margin: 10px;
                                    flex-shrink: 0;
                                    margin-left: auto;
                                }
                            }

                            & > .folder-item {
                                display: flex;
                                font-size: 0.8rem;
                                cursor: pointer;
                                align-items: center;

                                &:not(:last-child) {
                                    border-bottom: 1px solid $border-color;
                                }

                                &:hover, &.active {
                                    background-color: rgba(0, 0, 0, 0.1);
                                }
                                
                                .feed-item__img {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background: $border-color;
                                    padding: 10px;
                                    font-size: 1.2rem;
                                    margin: 10px;
                                    width: 40px;
                                }

                                .feed-item__info {
                                    margin: 10px;

                                    .feed-item__info__name {
                                        font-weight: 600;
                                    }
                                }

                                .feed-item__followers {
                                    margin: 10px;
                                    flex-shrink: 0;
                                    margin-left: auto;
                                }
                            }
                        }
                    }
                }

                .indpkg-slider-container {
                    padding: 0 20px;

                    @include desktop {
                        margin: 0 20px;
                        position: relative;
                    }
        
                    .next {
                        position: absolute;
                        right: 0;
                        top: calc(80px - 9px);
                        // top: 50%;
                        // transform: translateY(-50%);
                        cursor: pointer;
        
                        @include mobile {
                            display: none;
                        }
        
                    }
        
                    .prev {
                        position: absolute;
                        left: 0;
                        top: calc(80px - 9px);
                        // top: 50%;
                        // transform: translateY(-50%);
                        cursor: pointer;
        
                        @include mobile {
                            display: none;
                        }
                    }
        
                    .swiper-wrapper {
                        min-height: $thumbsizeHeight;
        
                        & > .swiper-slide {
                            width: $thumbsize;
        
                            .indpkg-slider-item {
                                position: relative;
                                // margin-right: 10px;
                                color: #333;
                                text-decoration: none;
                                cursor: pointer;
        
                                &:after {
                                    content:'\A';
                                    position:absolute;
                                    width:100%;
                                    height:100%;
                                    top:0; left:0;
                                    background:rgba(0,0,0,0.6);
                                    opacity:0;
                                    transition: all 0.5s;
                                    -webkit-transition: all 0.5s;
                                    height: $thumbsizeHeight;
                                }
        
                                .feed_image {
                                    width: 100%;
                                    // margin-bottom: 10px;
                                    background-color: $themeGrey3;
                                    border-radius: 4px;
                                }
        
                                .feed_name {
                                    font-size: 0.8rem;
                                    position: absolute;
                                    top: 0;
                                    width: 100%;
                                    text-align: center;
                                    color: #fff;
                                    padding: 10px 5px;
                                    z-index: 1;
                                }
        
                                .feed_action {
                                    display: none;
                                    position: absolute;
                                    width: 100%;
                                    height: 40px;
                                    top: calc(#{$thumbsize} - 40px);
                                    z-index: 100;
                                    background: transparent;
                                    font-size: 1rem;
                                    justify-content: space-around;
                                    align-items: center;
                                    color: #000;
        
                                    .feed_item {
                                        cursor: pointer;
                                        background: white;
                                        height: 26px;
                                        line-height: 26px;
                                        width: 26px;
                                        border-radius: 50%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        @extend .shadow;
        
                                        &.feed_play {
        
                                        }
        
                                        &.feed_follow {
                                            
                                        }
        
                                    }
                                }
        
                                &:hover {
        
                                    &:after {
                                        opacity:1;
                                    }
        
                                    .feed_image {
                                        // filter:grayscale(100%);
                                        // filter: blur(4px);
                                        border-radius: 4px;
                                    }
        
                                    .feed_action {
                                        display: flex;
                                    }
                                }
                            }
        
                        }
                    }
                }

            }
        }

        

    }
}
