$rsBottomHeightMobile: 84px;

.main.settings.organize--digest {
    
    .main_container {
        overflow-y: hidden;

        .main_container-outer {

            .main_container_wrapper {
                position: relative;
                padding: 0;
            }

        }
    }

    .oragnize_container {
        background-color: $themeGrey3;
        height: calc(100vh - #{$navbarHeight} - #{$rsTopHeight} - #{$rsBottomHeightMobile} - #{$settingsHeight});
        height: calc(100dvh - #{$navbarHeight} - #{$rsTopHeight} - #{$rsBottomHeightMobile} - #{$settingsHeight});

        @include desktop {
            height: calc(100vh - #{$navbarHeight} - #{$rsTopHeight} - #{$rsBottomHeight} - #{$settingsHeight});
            height: calc(100dvh - #{$navbarHeight} - #{$rsTopHeight} - #{$rsBottomHeight} - #{$settingsHeight});
        }
    }

}    