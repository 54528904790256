.rc-tree-contextmenu {
    position: absolute;
    left: -9999px;
    top: -9999px;
    z-index: 9998;
    display: block;
    background-color: #fff;
    // color: #212529;
    color: $dark;
    // width: 252px;
    // max-width: 100%;
  }
.rc-tree-contextmenu-hidden {
  display: none;
}
.rc-tree-contextmenu-inner {
  // border: 1px solid #ddd;
  // padding: 10px 20px;
  @extend .shadow;
}

.rc-tree-contextmenu-arrow {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;   /* Increase this for a wider arrow */
  border-right: 40px solid transparent;  /* Increase this for a wider arrow */
  border-top: 22px solid #000000;        /* Increase this for a taller arrow */
  position: absolute;
}

.rc-tree-contextmenu-placement-top .rc-tree-contextmenu-arrow {
  bottom: -49px; /* Adjust to position the arrow correctly */
  left: -54px;
  transform: translateX(-50%);
}
