// .c-p {
//   cursor: pointer;
// }

* {
    // font-family: 'Montserrat', sans-serif;
    font-family: 'Open Sans', sans-serif;
}

// html, body {
//     // font-size: 14px;
//     font-size: 16px;
//     // line-height: 18px;
//     line-height: 1.5;
//     max-width: 1920px;
//     margin: 0 auto;
// }
// @media (max-resolution: 96dpi) {
//     html, body {
//         font-size: 16px;
//     }
// }

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.cp {
    cursor: pointer;
}

.dropdown {
    &.no-caret {
        .dropdown-toggle::after {
            display: none !important;
        }
    }
}

.btn-xs {
    padding: 0.2rem 0.4rem;
    font-size: 0.6rem;
}

.text-truncate-two {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-truncate-two-md {
    @include desktop {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.text-light {
    --color-1: #{$white};
    --color-2: #{$white};
}
.text-primary {
    --color-1: #2ca4fc;
    --color-2: #{$primary};
}
.text-gold {
    --color-1: #{$themeGold};
    --color-2: #c9a423;
}
.text-secondary {
    --color-1: #{$secondary};
    --color-2: #{$secondary};
}

.bg-themeGrey {
    background-color: #F8F8FA;
}
.bg-themeGrey2 {
    background-color: #686D72;
}
.bg-themeYellow {
    background-color: #F9D8304D;
}
.bg-primary-light {
    background-color:lighten($primary, 30%);
}
.bg-themeGold {
    background-color: $themeGold;
}

.btn-mark-more {
    background-color: darken($secondary, 10%);
    border-color: darken($secondary, 10%);
}

.dropdown-header {
    padding: 0.5rem 1.25rem;
}