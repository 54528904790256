.action__bar {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .action__bar--left {
        display: flex;
        align-items: center;
    }

    .action__bar--right {
        display: flex;
        align-items: center;
        flex-shrink: 0;
    }

    .item--scheduler {
        border: 1px solid $themeGrey2;
        font-size: 10px;
        line-height: 15px;
        padding: 2px 5px;
        margin-left: 5px;
        border-radius: 2px;
        
        &:hover {
            color: #212529;
            background-color: $themeGrey3;
            // background-color: #d1d1d6;
        }
    }

    .item {
        width: 30px;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        text-align: center;
        
        &:hover {
            color: #212529;
            // background-color: #d1d1d6;
        }
    }
}