.features-container {
    max-width: $pageWidth;
    margin: 0 auto;
    padding: 30px 0.75rem;

    @include mobile {
        padding: 30px 0;
    }

    .header {
        text-align: center;
        font-size: 2rem;
        line-height: 2.4rem;
        padding: 1rem;
        margin-bottom: 30px;
    }

    .features-wrapper {
        display: grid;
        grid-gap: 40px;
        grid-template-columns: repeat(2, 1fr);

        @include desktop {
            grid-template-columns: repeat(6, 1fr);
        }

        & > .features-card {
            text-align: center;
            padding: 20px;
            border-radius: 10px;
            grid-column: span 2;
            @extend .shadow;

            &.features-card--1 {
                @include desktop {
                    grid-column-start: 2;
                    grid-column-end: 4;
                }
            }

            .title {
                font-weight: 600;
                margin-top: 20px;
                margin-bottom: 4px;
                font-size: 0.9rem;
            }

            .desc {
                font-size: 0.8rem;
            }
        }
    }
}