.sidebar {

    &.right {
        .feed-info {
            height: inherit;
            overflow: auto;

            .feed-img {
                background: lighten($primary, 40%);
                border-radius: 50% !important;
            }

            .primary {
                display: flex;
                align-items: center;

                .left {
                    padding: 10px;
                }

                .right {
                    padding: 10px 10px 10px 0;
                }
            }

            .secondary {
                padding: 10px;

                .feed-title {
                    font-size: 1.2rem;
                    font-weight: 500;
                }

                .feed-domain {
                    display: flex;
                    word-break: break-all;
                }

                .feed-url {
                    display: flex;
                    word-break: break-all;
                }

                .feed-frequency {
                    display: flex;
                    word-break: break-all;
                }
            
            }

        }
    }
}