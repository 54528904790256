:root {
  --font-size-base: 1rem; /* Default (16px) */
}

/* Apply Base Font Size */

html,
body {
  font-size: var(--font-size-base);
  line-height: 1.5;
  max-width: 1920px;
  margin: 0 auto;
}

.wfc{
  width:fit-content;
}

.font-sm{
  font-size: 0.875rem;
}
// .card-height{
//   height: 3rem;
// }

.preserve-line-breaks {
  white-space: pre-wrap;
}
.gp-75 { gap: 0.75rem; }
.gp-50 { gap: 0.5rem; }
.gp-80 { gap: 0.8rem; }
.gp-20 { gap: 0.2rem; }

.new-btn-dim{
  height: 2.25rem;
  width: 2.25rem;
  font-size: 1.25rem !important;
}

.play-enq-dim{
  font-size: 1.25rem;
}

.site-font-size{
  font-size: 0.85rem;
}

mark {
  background-color: rgba(249, 216, 48, 0.4) !important;
  color: rgba(0, 0, 0, 0.7);
  padding: 2px !important;
}
.h-100{
  height:100%;
}
.prof-icon {
  width : 1em !important;
  height : 1em !important;
  border-radius :50%;
  margin-left : 5px !important;
  // cursor : pointer;
  display : inline !important;
  margin-bottom: 5px !important;
}


.custom-drp {
  background-color: rgba(0, 0, 0,1) !important; // Black background with 90% opacity
  min-width: 7rem !important;
  font-size: 16px !important;  

  .dropdown-item {
    color: white !important; // White text color for the dropdown items
    width: fit-content !important;
    &:hover{
      background-color: transparent;
    }
  }

  // &::before {
  //   content: "";
  //   position: absolute;
  //   bottom: -10px; /* Adjust to align properly */
  //   left: 50%;
  //   transform: translateX(-50%);
  //   border-width: 10px;
  //   border-style: solid;
  //   border-color: #333 transparent transparent transparent;
  // }

}

.break-all{
  word-break: break-all;
}
.break-word{
  word-break: break-word;
}

.sharing--more-btn{
  font-family: 'Open Sans', sans-serif !important;
}

.dropdown-menu.show{
  max-height: 90vh;
  overflow-x: auto;
  
}

.sp-dropdown-item{
  white-space: normal !important;
  word-break: break-all;
  width:30vw !important;

  
}

.sp-dropdown-item-mobile{
  white-space: normal !important;
  word-break: break-all;
  width:99vw !important;
}

.res-flex-dir{
  flex-direction: row;
}

@media (max-width: 1024px) {
  .res-flex-dir {
    flex-direction: column;
  }
}


.merriweather {
  font-family: "Merriweather-Regular";
  * {
    font-family: inherit;
  }
}
.sans-serif {
  font-family: sans-serif;
  * {
    font-family: inherit;
  }
}
.itc-charter {
  font-family: "ITC Charter";
  * {
    font-family: inherit;
  }
}
.amasis {
  font-family: "Amasis MT";
  * {
    font-family: inherit;
  }
}
.ysobel {
  font-family: "Ysobel W01";
  * {
    font-family: inherit;
  }
}
.iowan {
  font-family: "Iowan Old Style";
  * {
    font-family: inherit;
  }
  &-bold{
    font-family: "IowanOldSt BT Bold";
  }
}
.mundo {
  font-family: "MundoSansW01";
  * {
    font-family: inherit;
  }
}
.open-dys {
  font-family: "OpenDyslexic";
  * {
    font-family: inherit;
  }
  &-bold{
    font-family: "OpenDyslexic Bold";
  }
}

.noto {
  font-family: "NotoSans-Regular";
  * {
    font-family: inherit;
  }
}
.hide-scrollbar {
  overflow-y: auto; /* Enables vertical scrolling */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

/* For Chrome, Safari and Edge */
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hides scrollbar */
}
